import { useLazyQuery } from '@apollo/client';
import { Input, Row, Col, Card, Form, FormInstance, Select } from 'antd';
import { getCategoriesForOptions } from 'containers/website/categories/utils';
import { getPagesForOptions } from 'containers/website/pages/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Page, PageCategory, Query, QueryPageCategoriesArgs, QueryPagesArgs } from 'queries/types';
import { GetWebsiteCategories } from 'queries/websiteCategories';
import { GetWebsitePages } from 'queries/websitePages';

type CategoriesFormProps = {
  form: FormInstance;
  props: any;
};

export const CategoriesCreateEditForm = ({ form }: CategoriesFormProps) => {
  const { t } = useTranslation();

  const I18N_PREFIX = 'website.categories.form';
  const I18N_PREFIX_FIELDS = `${I18N_PREFIX}.fields`;

  const [getCategories, { data: dataCategories, loading: loadingCategories }] = useLazyQuery<
    Query,
    QueryPageCategoriesArgs
  >(GetWebsiteCategories, {
    variables: {
      filter: {
        parentUuid: null,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const categoriesOptions = getCategoriesForOptions(
    dataCategories?.pageCategories.edges as PageCategory[]
  );

  useEffect(() => {
    getCategories();
  }, []);

  const [getPages, { data: dataPages, loading: loadingPages }] = useLazyQuery<
    Query,
    QueryPagesArgs
  >(GetWebsitePages, {
    variables: {},
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const pagesOptions = getPagesForOptions(dataPages?.pages.edges as Page[]);

  useEffect(() => {
    getPages();
  }, []);

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={24}>
        <Card>
          <Form.Item
            name="title"
            key="title"
            label={t(`${I18N_PREFIX_FIELDS}.title.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.title.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.title.placeholder`)} />
          </Form.Item>

          <Form.Item
            name="externalUrl"
            key="externalUrl"
            label={t(`${I18N_PREFIX_FIELDS}.externalUrl.field`)}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.externalUrl.placeholder`)} />
          </Form.Item>

          {!loadingPages && (
            <Form.Item name="page" key="page" label={t(`${I18N_PREFIX_FIELDS}.page.field`)}>
              <Select className="w-100" placeholder={t(`${I18N_PREFIX_FIELDS}.page.placeholder`)}>
                <Select.Option value={''}>{t(`${I18N_PREFIX_FIELDS}.page.none`)}</Select.Option>
                {pagesOptions.map(
                  (pageOption) =>
                    pageOption && (
                      <Select.Option key={pageOption?.uuid} value={pageOption?.uuid}>
                        {pageOption?.title.pt}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>
          )}

          {!loadingCategories && (
            <Form.Item
              name="parentPageCategory"
              key="parentPageCategory"
              label={t(`${I18N_PREFIX_FIELDS}.parentPageCategory.field`)}
            >
              <Select
                className="w-100"
                placeholder={t(`${I18N_PREFIX_FIELDS}.parentPageCategory.placeholder`)}
              >
                <Select.Option value={''}>
                  {t(`${I18N_PREFIX_FIELDS}.parentPageCategory.none`)}
                </Select.Option>
                {categoriesOptions.map(
                  (categoryOption) =>
                    categoryOption && (
                      <Select.Option key={categoryOption?.uuid} value={categoryOption?.uuid}>
                        {categoryOption?.title.pt}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>
          )}
        </Card>
      </Col>
    </Row>
  );
};
