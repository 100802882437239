import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { PageHeader } from 'components/pageHeader';
import { WebsiteCategoriesList } from 'components/websiteCategories/WebsiteCategoriesList';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

export const Categories = () => {
  const { t } = useTranslation();

  const routes = [
    {
      path: ROUTES.Website,
      breadcrumbName: t('mainMenu.website.default'),
    },
    {
      path: ROUTES.WebsiteCategories,
      breadcrumbName: t(`mainMenu.website.categories`),
    },
  ];

  return (
    <>
      <StyledPageHeader
        title={t('mainMenu.website.categories')}
        breadcrumbs={{ routes, separator: '/' }}
      />
      <WebsiteCategoriesList />
    </>
  );
};
