import { Layout } from 'antd';
import { Advertisement } from 'containers/advertisement';
import { Highlights } from 'containers/highlights';
import { Login } from 'containers/login';
import { Press } from 'containers/press';
import { Social } from 'containers/social';
import { SportsChampionships } from 'containers/sports/championships';
import { SportsMatch } from 'containers/sports/match';
import { SportsPlayers } from 'containers/sports/players';
import { SportsTeams } from 'containers/sports/teams';
import { Website } from 'containers/website';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import PrivateRoute from './PrivateRoute';
import { getCreatePage, getEditPage, ROUTES } from './config';

import { CategoriesCreateEdit } from 'components/categoriesCreateEdit';
import { Header } from 'components/header';
import { SideMenu } from 'components/sideMenu';
import { SportsChampionshipCompetitionCreateEdit } from 'components/sportsChampionshipCompetitionCreateEdit';
import { SportsChampionshipCreateEdit } from 'components/sportsChampionshipCreateEdit';
import { SportsChampionshipDetails } from 'components/sportsChampionshipDetails';
import { SportsChampionshipGroupCreateEdit } from 'components/sportsChampionshipGroupCreateEdit';
import { ChampionshipMatchAddTeams } from 'components/sportsChampionshipMatch/championshipMatchAddTeams';
import { ChampionshipMatchCreate } from 'components/sportsChampionshipMatch/championshipMatchCreate';
import { SportsChampionshipStageCreateEdit } from 'components/sportsChampionshipStageCreateEdit';
import { SportsChampionshipTeam } from 'components/sportsChampionshipTeam';
import { SportsPlayersCreateEdit } from 'components/sportsPlayersCreateEdit';
import { SportsTeamsCreateEdit } from 'components/sportsTeamsCreateEdit';

export const AppRouter = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.Auth} component={Login} />
      <Route exact path={ROUTES.Login} component={Login} />
      <PrivateRoute path={ROUTES.Home} component={MainApp} />
    </Switch>
  );
};

const AppContent = styled.div`
  padding-left: 225px;
`;

const MainApp = () => {
  return (
    <>
      <Header />
      <Layout className="app-container">
        <SideMenu />
        <Layout className="app-layout">
          <AppContent className="app-content">
            <Switch>
              <PrivateRoute path={ROUTES.Social} component={Social} />
              <PrivateRoute
                path={getEditPage('categories', ':uuid')}
                component={CategoriesCreateEdit}
                exact={true}
              />
              <PrivateRoute
                path={getCreatePage('categories')}
                component={CategoriesCreateEdit}
                exact={true}
              />
              <PrivateRoute path={ROUTES.Press} component={Press} />
              <PrivateRoute path={ROUTES.Highlights} component={Highlights} />
              <PrivateRoute path={ROUTES.Advertisement} component={Advertisement} />
              <PrivateRoute path={ROUTES.SportsTeams} component={SportsTeams} exact />
              <PrivateRoute
                path={`${ROUTES.SportsTeams}${ROUTES.Create}`}
                component={SportsTeamsCreateEdit}
                exact
              />
              <PrivateRoute
                path={`${ROUTES.SportsTeams}${ROUTES.Edit}/:uuid`}
                component={SportsTeamsCreateEdit}
                exact
              />
              <PrivateRoute
                path={`${ROUTES.SportsTeams}${ROUTES.Edit}/:uuid/:section`}
                component={SportsTeamsCreateEdit}
                exact
              />

              <PrivateRoute path={ROUTES.SportsPlayers} component={SportsPlayers} exact />
              <PrivateRoute
                path={`${ROUTES.SportsPlayers}${ROUTES.Create}`}
                component={SportsPlayersCreateEdit}
                exact
              />
              <PrivateRoute
                path={`${ROUTES.SportsPlayers}${ROUTES.Edit}/:uuid`}
                component={SportsPlayersCreateEdit}
                exact
              />

              <PrivateRoute
                path={ROUTES.SportsChampionships}
                component={SportsChampionships}
                exact
              />
              <PrivateRoute
                path={`${ROUTES.SportsChampionships}${ROUTES.Create}`}
                component={SportsChampionshipCreateEdit}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}${ROUTES.Edit}/:uuid`}
                component={SportsChampionshipCreateEdit}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}${ROUTES.Edit}/:uuid/:section`}
                component={SportsChampionshipCreateEdit}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid`}
                component={SportsChampionshipDetails}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/competition${ROUTES.Create}`}
                component={SportsChampionshipCompetitionCreateEdit}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/competition${ROUTES.Edit}/:competition`}
                component={SportsChampionshipCompetitionCreateEdit}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/:competition`}
                component={SportsChampionshipDetails}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/stage/create`}
                component={SportsChampionshipStageCreateEdit}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/:competition/stage/create`}
                component={SportsChampionshipStageCreateEdit}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/:competition/stage/edit/:stage`}
                component={SportsChampionshipStageCreateEdit}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/:competition/:stage`}
                component={SportsChampionshipDetails}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/:competition/group/create`}
                component={SportsChampionshipGroupCreateEdit}
                exact
              />
              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/:competition/:stage/group/create`}
                component={SportsChampionshipGroupCreateEdit}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/:competition/:stage/group/edit/:group`}
                component={SportsChampionshipGroupCreateEdit}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/:competition/:stage/group/edit/:group/:section`}
                component={SportsChampionshipGroupCreateEdit}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/:competition/:stage/:group`}
                component={SportsChampionshipDetails}
                exact
              />

              <PrivateRoute
                path={`${ROUTES.SportsChampionships}/:uuid/:competition/:stage/:group/:section`}
                component={SportsChampionshipDetails}
                exact
              />

              <PrivateRoute
                exact
                path={`${ROUTES.SportsMatch}/:uuid/teams/add`}
                component={ChampionshipMatchAddTeams}
              />

              <PrivateRoute
                exact
                path={`${ROUTES.SportsMatch}/create/:group`}
                component={ChampionshipMatchCreate}
              />

              <PrivateRoute path={`${ROUTES.SportsMatch}/:uuid/:section`} component={SportsMatch} />

              <PrivateRoute
                exact
                path={`${ROUTES.SportsChampionship}/:uuid/team/:championshipTeam/:team`}
                component={SportsChampionshipTeam}
              />

              <PrivateRoute path={ROUTES.Website} component={Website} />

              <Route path={ROUTES.Home} />
            </Switch>
          </AppContent>
        </Layout>
      </Layout>
    </>
  );
};
