import format from 'format-duration';

import { ChampionshipScoreUnit } from 'queries/types';

export const convertToHours = (value: number, unit: ChampionshipScoreUnit) => {
  switch (unit) {
    case ChampionshipScoreUnit.Minutes:
      return value / 60;

    case ChampionshipScoreUnit.Seconds:
      return value / 60 / 60;

    case ChampionshipScoreUnit.Milliseconds:
      return value / 1000 / 60 / 60;

    default:
      return value;
  }
};

export const convertToMinutes = (value: number, unit: ChampionshipScoreUnit) => {
  switch (unit) {
    case ChampionshipScoreUnit.Hours:
      return value * 60;

    case ChampionshipScoreUnit.Seconds:
      return value / 60;

    case ChampionshipScoreUnit.Milliseconds:
      return value / 1000 / 60;

    default:
      return value;
  }
};

export const convertToSeconds = (value: number, unit: ChampionshipScoreUnit) => {
  switch (unit) {
    case ChampionshipScoreUnit.Hours:
      return value * 60 * 60;

    case ChampionshipScoreUnit.Minutes:
      return value * 60;

    case ChampionshipScoreUnit.Milliseconds:
      return value / 1000;

    default:
      return value;
  }
};

export const convertToMilliseconds = (value: number, unit: ChampionshipScoreUnit) => {
  switch (unit) {
    case ChampionshipScoreUnit.Hours:
      return value * 1000 * 60 * 60;

    case ChampionshipScoreUnit.Minutes:
      return value * 1000 * 60;

    case ChampionshipScoreUnit.Seconds:
      return value * 1000;

    default:
      return value;
  }
};

export const convertToMeters = (value: number, unit: ChampionshipScoreUnit) => {
  switch (unit) {
    case ChampionshipScoreUnit.Centimeters:
      return value / 100;

    default:
      return value;
  }
};

export const convertToCentimeters = (value: number, unit: ChampionshipScoreUnit) => {
  switch (unit) {
    case ChampionshipScoreUnit.Meters:
      return value * 100;

    default:
      return value;
  }
};

export const getInputMaskByUnit = (unit: ChampionshipScoreUnit) => {
  switch (unit) {
    case ChampionshipScoreUnit.Milliseconds:
      return '9999';
    case ChampionshipScoreUnit.Seconds:
      return '999999:999';
    case ChampionshipScoreUnit.Minutes:
      return '9999:99:999';
    case ChampionshipScoreUnit.Hours:
      return '9999:99:99:999';
    case ChampionshipScoreUnit.Meters:
    case ChampionshipScoreUnit.Centimeters:
      return '9999,9999';
    default:
      return '';
  }
};

const addPrefixtoNumber = (value: string, length: number = 2) => {
  return `0000000${value || '0'}`.slice(length * -1);
};

export const convertScoreForResponse = (
  score: string,
  originalUnit: ChampionshipScoreUnit,
  targetUnit: ChampionshipScoreUnit
) => {
  const newScore = score.replaceAll('_', '0');

  const [milliseconds, seconds, minutes, hours] = newScore.split(':').reverse();

  switch (targetUnit) {
    case ChampionshipScoreUnit.Seconds:
      return `${
        convertToSeconds(Number(hours), ChampionshipScoreUnit.Hours) +
        convertToSeconds(Number(minutes), ChampionshipScoreUnit.Minutes) +
        convertToSeconds(Number(seconds), ChampionshipScoreUnit.Seconds) +
        convertToSeconds(Number(milliseconds), ChampionshipScoreUnit.Milliseconds)
      }`;

    case ChampionshipScoreUnit.Centimeters:
      return `${convertToCentimeters(Number(newScore.replaceAll(',', '.')), originalUnit)}`;
  }

  return score;
};

export const convertScoreForRender = (
  score: number,
  originalUnit: ChampionshipScoreUnit,
  targetUnit: ChampionshipScoreUnit
) => {
  switch (originalUnit) {
    case ChampionshipScoreUnit.Seconds:
      const formattedHoursMinutesSeconds = format(convertToMilliseconds(score, originalUnit));
      const formattedMilliseconds = convertToMilliseconds(
        score - parseInt(score.toString(), 10),
        originalUnit
      ).toFixed(0);

      const [
        milliseconds,
        seconds,
        minutes,
        hours,
      ] = `${formattedHoursMinutesSeconds}:${formattedMilliseconds}`.split(':').reverse();

      switch (targetUnit) {
        case ChampionshipScoreUnit.Hours:
          return [
            addPrefixtoNumber(hours, 4),
            addPrefixtoNumber(minutes),
            addPrefixtoNumber(seconds),
            addPrefixtoNumber(milliseconds, 3),
          ];

        case ChampionshipScoreUnit.Minutes:
          return [
            addPrefixtoNumber(minutes, 4),
            addPrefixtoNumber(seconds),
            addPrefixtoNumber(milliseconds, 3),
          ];

        case ChampionshipScoreUnit.Seconds:
          return [addPrefixtoNumber(seconds, 6), addPrefixtoNumber(milliseconds, 3)];
      }
      break;
    case ChampionshipScoreUnit.Centimeters:
      switch (targetUnit) {
        case ChampionshipScoreUnit.Meters:
          const [meters, decimals] = convertToMeters(score, ChampionshipScoreUnit.Centimeters)
            .toString()
            .split('.')
            .reverse();

          return [addPrefixtoNumber(meters, 4), addPrefixtoNumber(decimals, 4)].join(',');

        case ChampionshipScoreUnit.Centimeters:
          const [centimeters, milimeters] = score.toString().split('.').reverse();

          return [addPrefixtoNumber(centimeters, 4), addPrefixtoNumber(milimeters, 4)].join(',');
      }
      break;
    default:
      return score;
  }
};
