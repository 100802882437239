import { useMutation } from '@apollo/client';
import { Button, Dropdown, Spin, Menu } from 'antd';
import { ColorMain } from 'config/main';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { sortCompetitionsByName } from './utils';

import { RemoveModal } from 'components/removeModal';

import { Icon3Dots } from 'assets/icons/icon3Dots';
import { IconDelete } from 'assets/icons/iconDelete';
import { IconPlus } from 'assets/icons/iconPlus';
import { IconSettings } from 'assets/icons/iconSettings';

import { DeleteChampionshipCompetition } from 'queries/sportsChampionshipCompetitions';
import { DeleteChampionshipStage } from 'queries/sportsChampionshipStages';
import {
  ChampionshipCompetition,
  ChampionshipStage,
  Mutation,
  MutationDeleteChampionshipCompetitionArgs,
  MutationDeleteChampionshipStageArgs,
} from 'queries/types';

const Container = styled.div`
  border: 1px solid #eef2f8;
  background: white;
  width: 25%;
`;

const StyledH4 = styled.h4`
  padding: 20px 23px;
  margin: 0;
  border-bottom: 1px solid #eef2f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledUl = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  max-height: calc(100vh - 320px);
  overflow-y: auto;
`;

const StyledLi = styled.li`
  padding: 23px;
  transition: background-color 0.25s ease-in-out;
  flex-grow: 1;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eef2f8;

  &:hover,
  &:focus,
  &.active {
    cursor: pointer;
    background-color: #f7f7f8;

    h6 {
      color: #9845fd;
    }
  }
`;

const Name = styled.h6`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 3px;
  transition: color 0.25s ease-in-out;
`;

const Sport = styled.span`
  font-size: 12px;
  line-height: 15px;
  display: block;
  color: #9fa8b7;
`;

interface CompetitionsProps {
  data?: ChampionshipCompetition[] | null | undefined;
  loading?: boolean;
}

export const Competitions: React.VFC<CompetitionsProps> = ({
  data,
  loading,
}: CompetitionsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [competitionIdToRemove, setCompetitionIdToRemove] = useState<string>('');

  const { competition: competitionId, uuid } = useParams<{
    competition: string;
    uuid: string;
  }>();

  const [deleteCompetition, { loading: isRemoving, data: isRemoved }] = useMutation<
    Mutation,
    MutationDeleteChampionshipCompetitionArgs
  >(DeleteChampionshipCompetition);
  useEffect(() => {
    if (isRemoved) {
      history.push(`${ROUTES.SportsChampionships}/${uuid}`);
      window.location.reload();
    }
  }, [isRemoved]);

  const competitionsSortedByName = sortCompetitionsByName(
    (data as ChampionshipCompetition[]) || []
  );

  return (
    <Container>
      <StyledH4>
        {t('sportsChampionship.sections.competitions')}
        <Button
          onClick={() => history.push(`${ROUTES.SportsChampionships}/${uuid}/competition/create`)}
          icon={<IconPlus width="15" height="15" fill={ColorMain} />}
          shape="circle"
          type="text"
        />
      </StyledH4>
      {!loading ? (
        <>
          <StyledUl>
            {competitionsSortedByName &&
              competitionsSortedByName.map((competition: ChampionshipCompetition) => (
                <Wrapper
                  key={competition.uuid}
                  className={competitionId === competition.uuid ? 'active' : ''}
                >
                  <StyledLi
                    onClick={() =>
                      history.push(`${ROUTES.SportsChampionships}/${uuid}/${competition.uuid}`)
                    }
                  >
                    <Name>{competition.name.pt}</Name>
                    <Sport>{competition.championshipSport.name.pt}</Sport>
                  </StyledLi>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key={`competitions-stages-edit-${competition.uuid}`}>
                          <Link
                            to={`${ROUTES.SportsChampionships}/${uuid}/competition/edit/${competition.uuid}`}
                          >
                            <IconSettings width="12px" height="12px" fill="black" /> Editar
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key={`competitions-stages-delete-${competition.uuid}`}
                          onClick={() => setCompetitionIdToRemove(competition.uuid)}
                        >
                          <div>
                            <IconDelete width="12px" height="12px" fill="black" /> Apagar
                          </div>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Button
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                      icon={<Icon3Dots width="15" height="15" />}
                      shape="circle"
                      type="text"
                    ></Button>
                  </Dropdown>
                </Wrapper>
              ))}
          </StyledUl>
          <RemoveModal
            title={t('modals.sportsChampionshipCompetitionRemoveModal.title')}
            message={t('modals.sportsChampionshipCompetitionRemoveModal.message')}
            isLoading={isRemoving}
            showModal={!!competitionIdToRemove}
            onClose={() => setCompetitionIdToRemove('')}
            onRemoveConfirm={() =>
              deleteCompetition({
                variables: {
                  uuid: competitionIdToRemove,
                },
              }).catch((error) => {
                console.log('Error:', error);
                setCompetitionIdToRemove('');
              })
            }
          />
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

interface StagesProps {
  data?: ChampionshipStage[] | null | undefined;
  loading?: boolean;
}

export const Stages: React.VFC<StagesProps> = ({ data, loading }: StagesProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [stageIdToRemove, setStageIdToRemove] = useState<string>('');

  const {
    competition,
    stage: stageUrl,
    uuid,
  } = useParams<{
    competition: string;
    stage: string;
    uuid: string;
  }>();

  const [deleteStage, { loading: isRemoving, data: isRemoved }] = useMutation<
    Mutation,
    MutationDeleteChampionshipStageArgs
  >(DeleteChampionshipStage);
  useEffect(() => {
    if (isRemoved) {
      history.push(`${ROUTES.SportsChampionships}/${uuid}/${competition}`);
      window.location.reload();
    }
  }, [isRemoved]);

  return (
    <Container>
      <StyledH4>
        {t('sportsChampionship.sections.stages')}
        <Button
          onClick={() =>
            history.push(
              `${ROUTES.SportsChampionships}/${uuid}/${
                competition ? `${competition}/` : ''
              }stage/create`
            )
          }
          icon={<IconPlus width="15" height="15" fill={ColorMain} />}
          shape="circle"
          type="text"
        />
      </StyledH4>
      {!loading ? (
        <>
          <StyledUl>
            {data &&
              data.map((stage: ChampionshipStage) => (
                <Wrapper key={stage.uuid} className={stageUrl === stage.uuid ? 'active' : ''}>
                  <StyledLi
                    onClick={() =>
                      history.push(
                        `${ROUTES.SportsChampionships}/${stage.championshipCompetition.championship.uuid}/${stage.championshipCompetition.uuid}/${stage.uuid}`
                      )
                    }
                  >
                    <Name>{stage.name.pt}</Name>
                  </StyledLi>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key={`competitions-stages-edit-${stage.uuid}`}>
                          <Link
                            to={`${ROUTES.SportsChampionships}/${uuid}/${competition}/stage/edit/${stage.uuid}`}
                          >
                            <IconSettings width="12px" height="12px" fill="black" /> Editar
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key={`competitions-stages-delete-${stage.uuid}`}
                          onClick={() => setStageIdToRemove(stage.uuid)}
                        >
                          <div>
                            <IconDelete width="12px" height="12px" fill="black" /> Apagar
                          </div>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Button
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                      icon={<Icon3Dots width="15" height="15" />}
                      shape="circle"
                      type="text"
                    ></Button>
                  </Dropdown>
                </Wrapper>
              ))}
          </StyledUl>
          <RemoveModal
            title={t('modals.sportsChampionshipStageRemoveModal.title')}
            message={t('modals.sportsChampionshipStageRemoveModal.message')}
            isLoading={isRemoving}
            showModal={!!stageIdToRemove}
            onClose={() => setStageIdToRemove('')}
            onRemoveConfirm={() =>
              deleteStage({
                variables: {
                  uuid: stageIdToRemove,
                },
              }).catch((error) => {
                console.log('Error:', error);
                setStageIdToRemove('');
              })
            }
          />
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: center;
`;

const Loading = () => {
  return (
    <LoadingWrapper>
      <Spin />
    </LoadingWrapper>
  );
};
