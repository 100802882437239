import { gql } from '@apollo/client';

export const GetFeedPost = gql`
  query($uuid: UUID!) {
    feedPost(uuid: $uuid) {
      title
      subtitle
      note
      category {
        uuid
        name
      }
      tags {
        uuid
        name
      }
      author {
        uuid
      }
      cover {
        url
        contentType
        width
        height
      }
      highlight {
        highlighted
        position
        stretch
        image {
          url
          contentType
          width
          height
        }
      }
      cta
      blocks
      type
      advertisement {
        page
        position
        startDate
        endDate
      }
      published {
        app
        site
      }
    }
  }
`;
