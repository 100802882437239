import { InputCreatePageCategory, Page, PageCategory } from 'queries/types';

export const transformFormValues = (values: any): InputCreatePageCategory => {
  return {
    title: {
      pt: values.title || '',
    },
    parentPageCategory: values.parentPageCategory || null,
    page: values.page || null,
    externalUrl: values.externalUrl || '',
  };
};

export const transformInitialValues = (
  pageCategory: PageCategory,
  parentPageCategory: PageCategory['uuid'],
  page?: Page['uuid']
): any => {
  return {
    title: pageCategory?.title?.pt || '',
    parentPageCategory: parentPageCategory || pageCategory?.parentPageCategory?.uuid || undefined,
    page: page || undefined,
    externalUrl: pageCategory?.externalUrl || '',
  };
};

export const getCategoriesForOptions = (categories?: PageCategory[]) => {
  if (!categories) return [];

  const firstLevelCategories = categories.map(({ uuid, title }) => ({ uuid, title }));
  const secondLevelCategories = categories.map(({ pageCategories }) => {
    return pageCategories && pageCategories.length > 0
      ? pageCategories.map((category) => ({ uuid: category!.uuid, title: category!.title })).flat()
      : undefined;
  });

  return [...firstLevelCategories, ...secondLevelCategories.flat(2)];
};
