import { FeedPostType } from 'queries/types';

export enum ROUTES {
  Auth = '/auth',
  Create = '/create',
  Edit = '/edit',
  Highlights = '/highlights',
  Home = '/',
  Login = '/login',
  Press = '/press',
  PressFeed = '/press/feed',
  PressNews = '/press/news',
  PressVideo = '/press/video',
  PressGallery = '/press/gallery',
  PressCategories = '/press/categories',
  Social = '/social',
  Advertisement = '/advertisement',
  Sports = '/sports',
  SportsTeams = '/sports/teams',
  SportsPlayers = '/sports/players',
  SportsChampionships = '/sports/championships',
  SportsMatch = '/sports/match',
  SportsChampionship = '/sports/championship',
  Website = '/website',
  WebsiteCategories = '/website/categories',
  WebsitePages = '/website/pages',
}

export function getCreatePage(section: FeedPostType | string) {
  return `${ROUTES.Press}/${section.toLowerCase()}${ROUTES.Create}`;
}

export function getEditPage(section: FeedPostType | string, uuid: string) {
  return `${ROUTES.Press}/${section.toLowerCase()}${ROUTES.Edit}/${uuid}`;
}
