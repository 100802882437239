import { useLazyQuery, useMutation } from '@apollo/client';
import { Form } from 'antd';
import { Store } from 'rc-field-form/lib/interface';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from 'routes/config';

import { Header } from './header';
import { SportsChampionshipForm, FormMode } from './sportsChampionshipForm';
import { SportsChampionshipTeam } from './sportsChampionshipTeam';
import { transformFormValues, transformInitialValues } from './utils';

import {
  CreateChampionship,
  GetChampionship,
  UpdateChampionship,
} from 'queries/sportsChampionship';
import { GetManagementEntities } from 'queries/sportsManagementEntities';
import {
  ChampionshipTeam,
  ManagementEntity,
  Mutation,
  MutationCreateChampionshipArgs,
  MutationUpdateChampionshipArgs,
  Query,
  QueryChampionshipArgs,
  QueryManagementEntitiesArgs,
} from 'queries/types';

export const SportsChampionshipCreateEdit = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { uuid, section } = useParams<{ uuid: string; section: string }>();

  const [createChampionship, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreateChampionshipArgs
  >(CreateChampionship);
  const [updateChampionship, { loading: isUpdating, data: isUpdated }] = useMutation<
    Mutation,
    MutationUpdateChampionshipArgs
  >(UpdateChampionship);

  const [getManagementEntities, { data: managementEntities }] = useLazyQuery<
    Query,
    QueryManagementEntitiesArgs
  >(GetManagementEntities, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getManagementEntities();
  }, []);

  useEffect(() => {
    if (isCreated) {
      history.push(`${ROUTES.SportsChampionships}`);
      window.location.reload();
    }
  }, [isCreated]);

  useEffect(() => {
    if (isUpdated) window.location.reload();
  }, [isUpdated]);

  const [getChampionship, { data: championship }] = useLazyQuery<Query, QueryChampionshipArgs>(
    GetChampionship,
    {
      variables: {
        uuid,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (uuid) getChampionship();
  }, [uuid]);

  const onFinish = async (values: any) => {
    if (uuid) {
      updateChampionship({
        variables: {
          uuid,
          input: transformFormValues(values),
        },
      });
    } else {
      createChampionship({
        variables: {
          input: transformFormValues(values),
        },
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (uuid && !championship?.championship) return null;

  return (
    <>
      {section === 'teams' ? (
        <SportsChampionshipTeam
          uuid={uuid}
          championshipTeams={championship?.championship.championshipTeams as ChampionshipTeam[]}
        />
      ) : (
        <Form
          layout="vertical"
          form={form}
          name="sports-championship-form"
          className="sports-championship-create-edit-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={
            championship?.championship
              ? (transformInitialValues(championship?.championship) as Store)
              : undefined
          }
        >
          <Header uuid={uuid} loading={isCreating || isUpdating} />
          <SportsChampionshipForm
            mode={FormMode.ADD}
            form={form}
            managementEntities={managementEntities?.managementEntities.edges as ManagementEntity[]}
            props
          />
        </Form>
      )}
    </>
  );
};
