import { useMutation, useLazyQuery } from '@apollo/client';
import { Card, Table, Button } from 'antd';
import { ITEMS_PER_PAGE } from 'config/main';
import { getTablePagination } from 'helpers/tablePagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { getCreatePage, getEditPage, ROUTES } from 'routes/config';
import styled from 'styled-components';

import { ListActionElement, ListActionElementType } from 'components/listActionElement';
import { PostsTypeFilter } from 'components/postsTypeFilter';
import { SearchField } from 'components/searchField';
import { Tag } from 'components/tag';

import { IconPlus } from 'assets/icons/iconPlus';

import { GetPostListQuery, UpdatePublishedPost } from 'queries/postsList';
import {
  Category,
  FeedPost,
  Query,
  QueryFeedPostsArgs,
  Scalars,
  FeedPostOrigin,
  FeedPostType,
  InputPartialFeedPostPublished,
  Mutation,
  MutationPublishFeedPostArgs,
  Pagination,
  FeedPostOrderKeys,
  OrderDirection,
  InputFeedPostOrder,
  FeedPostPublishedType,
} from 'queries/types';

/**
 * STYLES SECTION
 */
const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
`;

/**
 * CONSTANTS
 */
const I18N_PREFIX: string = 'postsList';
const DEFAULT_ORDER_KEY = FeedPostOrderKeys.CreatedAt;

/**
 * INTERFACES
 */
interface Props {
  origin?: FeedPostOrigin[];
  types?: FeedPostType[];
  order?: InputFeedPostOrder;
  published?: FeedPostPublishedType[];
  section?: FeedPostType;
  createButton?: boolean;
  actionElementType?: ListActionElementType;
}

export const PostsList: React.VFC<Props> = ({
  origin = [FeedPostOrigin.Internal],
  types = [],
  order = { key: DEFAULT_ORDER_KEY, direction: OrderDirection.Desc },
  published,
  section,
  createButton = true,
  actionElementType = ListActionElementType.Toggle,
}: Props) => {
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [selectedPostType, setSelectedPostType] = useState<FeedPostType | string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { t } = useTranslation();
  const history = useHistory();

  const variables: QueryFeedPostsArgs = {
    filter: {
      query: searchQuery,
      type: selectedPostType === '' ? types : [selectedPostType as FeedPostType],
      origin: origin,
      published: published,
    },
    order: order,
    pagination: {
      page: selectedPage,
      take: ITEMS_PER_PAGE,
    },
  };

  const [getFeedPost, { data, loading }] = useLazyQuery<Query, QueryFeedPostsArgs>(
    GetPostListQuery,
    {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    getFeedPost();
  }, [getFeedPost]);

  const feedPosts = (data?.feedPosts.edges as FeedPost[]) || [];
  const pagination = data?.feedPosts.pagination as Pagination;

  const [setPublished, { loading: updateLoading }] = useMutation<
    Mutation,
    MutationPublishFeedPostArgs
  >(UpdatePublishedPost, {
    refetchQueries: [
      {
        query: GetPostListQuery,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  });

  const setPublishedFeedPost = (
    uuid: Scalars['UUID'],
    currentPublished: InputPartialFeedPostPublished
  ) => {
    const newPublished: InputPartialFeedPostPublished = {
      app: !currentPublished.app,
    };

    setPublished({
      variables: {
        uuid,
        published: newPublished,
      },
    });
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearchQuery(value);

    // reset pagination
    setSelectedPage(1);
  };

  const tableColumns = [
    {
      title: t(`${I18N_PREFIX}.type`),
      dataIndex: 'type',
      render: (type: string) => <Tag>{t(type)}</Tag>,
    },
    {
      title: t(`${I18N_PREFIX}.title`),
      dataIndex: 'title',
      render: (text: Scalars['TranslatableField'], post: FeedPost) => (
        <span className="font-weight-bold">
          {section ? (
            <Link to={`${getEditPage(section as FeedPostType, post.uuid)}`}>{text.pt}</Link>
          ) : (
            text.pt
          )}
        </span>
      ),
      width: '50%',
    },
    {
      title: t(`${I18N_PREFIX}.category`),
      dataIndex: 'category',
      render: (category: Category) => category && <Tag>{category?.name.pt || ''}</Tag>,
    },
    {
      title: order.key
        ? t(`${I18N_PREFIX}.${order.key}`)
        : t(`${I18N_PREFIX}.${DEFAULT_ORDER_KEY}`),
      dataIndex: order.key || DEFAULT_ORDER_KEY,
      render: (date: string) => <span>{moment(date).format('DD/MM/YYYY')}</span>,
    },
    {
      action: '',
      dataIndex: 'published',
      title:
        actionElementType === ListActionElementType.Toggle ? t(`${I18N_PREFIX}.published`) : '',
      render: (published: InputPartialFeedPostPublished, post: FeedPost) => (
        <ListActionElement
          type={actionElementType}
          action={() => setPublishedFeedPost(post.uuid, published)}
          checked={published.app}
        >
          {t('actions.hide')}
        </ListActionElement>
      ),
    },
  ];

  if ((section as FeedPostType) === FeedPostType.News) {
    tableColumns.pop();
  }

  const goToCreatePage = () => history.push(getCreatePage(section as FeedPostType));

  return (
    <Card>
      <CardWrapper>
        <div className="mb-1 d-flex">
          <div className="mr-md-3 mb-3">
            <SearchField onChange={onSearch} />
          </div>
          <div className="mb-3">
            <PostsTypeFilter
              selectedPostType={selectedPostType}
              onChange={setSelectedPostType}
              data={types}
            />
          </div>
        </div>
        {createButton && (
          <div>
            <Button
              onClick={goToCreatePage}
              type="primary"
              icon={<IconPlus className={'anticon'} width="15" height="16" />}
              block
            >
              {t('actions.create')} {t(`${I18N_PREFIX}.create.${section as string}`)}
            </Button>
          </div>
        )}
      </CardWrapper>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={feedPosts}
          loading={loading || updateLoading}
          rowKey="uuid"
          pagination={getTablePagination(pagination, setSelectedPage)}
        />
      </div>
    </Card>
  );
};
