import { gql } from '@apollo/client';

export const GetWebsitePages = gql`
  query GetWebsitePages {
    pages {
      edges {
        uuid
        title
      }
    }
  }
`;

export const GetWebsitePagesPaginated = gql`
  query GetWebsitePagesPaginated($pagination: InputPagePagination) {
    pages(pagination: $pagination) {
      edges {
        uuid
        createdAt
        modifiedAt
        deletedAt
        slug
        publishedAt
        title
        subtitle
        blocks
        cover {
          url
          contentType
          width
          height
        }
        note
        pageCategories {
          uuid
          title
          parentPageCategory {
            uuid
            title
            parentPageCategory {
              uuid
              title
            }
          }
        }
      }
    }
  }
`;

export const GetWebsitePage = gql`
  query GetWebsitePage($uuid: UUID!) {
    page(uuid: $uuid) {
      uuid
      slug
      title
      subtitle
      blocks
      cover {
        url
        contentType
        width
        height
      }
      note
    }
  }
`;

export const CreateWebsitePage = gql`
  mutation CreateWebsitePage($input: InputPage!) {
    createPage(input: $input) {
      uuid
    }
  }
`;

export const UpdateWebsitePage = gql`
  mutation UpdateWebsitePage($uuid: UUID!, $input: InputPage!) {
    updatePage(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const DeleteWebsitePage = gql`
  mutation DeleteWebsitePage($uuid: UUID!) {
    deletePage(uuid: $uuid) {
      uuid
    }
  }
`;
