import { LoadingOutlined } from '@ant-design/icons';
import { Input, Row, Col, Card, Form, FormInstance } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { ColorGrey05 } from 'config/main';
import { useUploadFile } from 'hooks/useUploadFile';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Editor, EditorMode } from 'assets/editorjs/editor';
import { IconDelete } from 'assets/icons/iconDelete';
import { IconImage } from 'assets/icons/iconImage';

import { Image } from 'queries/types';
import 'queries/websitePages';

type PagesFormProps = {
  form: FormInstance;
  props: any;
};

const EditorWrapper = styled.div``;

const DeleteButton = styled.button`
  background: #000;
  border: none;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin: -53px 0 0 15px;
`;

const I18N_PREFIX = 'website.pages.form';
const I18N_PREFIX_SECTIONS = `${I18N_PREFIX}.sections`;
const I18N_PREFIX_FIELDS = `${I18N_PREFIX}.fields`;

const imageUploadProps: any = {
  name: 'file',
  multiple: false,
  listType: 'picture-card',
  showUploadList: false,
};

export const PagesCreateEditForm = ({ form }: PagesFormProps) => {
  const { t } = useTranslation();

  const [blocksData, setBlocksData] = useState<any[]>(form.getFieldValue('blocks'));
  const [coverData, setCoverData] = useState<Image | null>(form.getFieldValue('cover'));

  const {
    isUploadFileLoading: isCoverLoading,
    uploadFileData: cover,
    uploadFile: uploadCover,
  } = useUploadFile();

  const handleUploadChange = (info: any) => {
    uploadCover(info.fileList[0].originFileObj);
  };

  useEffect(() => {
    form.setFieldsValue({
      cover: cover?.data ? cover.data : form.getFieldValue('cover'),
    });
    setCoverData(form.getFieldValue('cover'));
  }, [cover]);

  const handleRemoveMedia = () => {
    form.setFieldsValue({
      cover: null,
    });
    setCoverData(null);
  };

  useEffect(() => {
    form.setFieldsValue({
      blocks: blocksData ? blocksData : form.getFieldValue('blocks'),
    });

    if (!blocksData) {
      setBlocksData(form.getFieldValue('blocks'));
    }
  }, [blocksData]);

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={24}>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.basicInfo`)}>
          <Form.Item
            name="slug"
            key="slug"
            label={t(`${I18N_PREFIX_FIELDS}.slug.field`)}
            rules={[
              {
                required: true,
                pattern: /^[a-z0-9-]+$/,
                message: t(`${I18N_PREFIX_FIELDS}.slug.errorMessage`),
              },
            ]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.slug.placeholder`)} />
          </Form.Item>

          <Form.Item
            name="title"
            key="title"
            label={t(`${I18N_PREFIX_FIELDS}.title.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.title.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.title.placeholder`)} />
          </Form.Item>

          <Form.Item label={t(`${I18N_PREFIX_FIELDS}.cover.field`)} name="cover">
            <>
              <Input type="hidden" />
              <Dragger
                {...imageUploadProps}
                beforeUpload={() => false}
                onChange={handleUploadChange}
              >
                {isCoverLoading ? (
                  <div>
                    <LoadingOutlined className="font-size-xxl text-primary" />
                    <div className="mt-3">{t(`${I18N_PREFIX_FIELDS}.cover.uploading`)}</div>
                  </div>
                ) : coverData ? (
                  <img src={coverData.url || ''} alt="avatar" className="img-fluid" />
                ) : (
                  <div>
                    <IconImage width="64" height="64" />
                    <p>{t(`${I18N_PREFIX_FIELDS}.cover.placeholder`)}</p>
                  </div>
                )}
              </Dragger>
              {coverData && (
                <DeleteButton onClick={handleRemoveMedia}>
                  <IconDelete width="12" height="13" fill={ColorGrey05} />
                </DeleteButton>
              )}
            </>
          </Form.Item>
        </Card>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.blocks`)}>
          <EditorWrapper>
            <Editor blocks={blocksData} update={setBlocksData} mode={EditorMode.PAGES} />
            <Form.Item name="blocks">
              <Input type="hidden" />
            </Form.Item>
          </EditorWrapper>
        </Card>
      </Col>
    </Row>
  );
};
