import { useMutation } from '@apollo/client';
import { Table, Button, Input, Dropdown, Menu } from 'antd';
import { ColorGrey01, ColorGrey05 } from 'config/main';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Icon3Dots } from 'assets/icons/icon3Dots';
import { IconDelete } from 'assets/icons/iconDelete';
import { IconPlus } from 'assets/icons/iconPlus';

import { CreateChampionshipMatchEntities } from 'queries/sportsChampionshipMatchTeams';
import {
  ChampionshipEntity,
  ChampionshipMatchEntity,
  ChampionshipMatchEntityPlayer,
  Mutation,
  MutationCreateChampionshipMatchEntitiesArgs,
} from 'queries/types';

const Container = styled.div`
  background: white;
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const PlayerName = styled.span`
  font-weight: bold;
`;

const PlayerWrapper = styled.div`
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  text-align: center;
  width: 40px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const AddTeamButton = styled(Button)`
  background: ${ColorGrey01};
  color: white;
  display: flex;
  justify-content: center;
  margin: 25px 20px 50px;

  &:focus,
  &:hover {
    background: ${ColorGrey01} !important;
    color: ${ColorGrey05} !important;
  }
`;

interface Props {
  teams: ChampionshipMatchEntity[];
  loading: boolean;
  setNumber: any;
  setSubstitute: any;
  removePlayer: any;
  setTeamToAdd: any;
  setShowPlayerAddModal: any;
  removeTeam: any;
  setShowTeamsAddModal: any;
}

const I18N_PREFIX = 'sportsMatch.sections.teams';

export const TeamsGrid = ({
  teams,
  loading,
  setNumber,
  removePlayer,
  setTeamToAdd,
  setShowPlayerAddModal,
  removeTeam,
  setShowTeamsAddModal,
}: Props) => {
  const { t } = useTranslation();

  const tableColumns = [
    {
      title: t(`${I18N_PREFIX}.name`),
      dataIndex: 'championshipEntity',
      render: (championshipEntity: ChampionshipEntity, team: ChampionshipMatchEntity) => (
        <>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key={`competitions-teams-grid-add-${team.uuid}`}
                  onClick={() => {
                    setTeamToAdd(team);
                    setShowPlayerAddModal(true);
                  }}
                >
                  <div>
                    <IconPlus width="15" height="15" fill={ColorGrey01} />{' '}
                    {t(`${I18N_PREFIX}.addPlayer`)}
                  </div>
                </Menu.Item>
                <Menu.Item
                  key={`competitions-teams-grid-delete-${team.uuid}`}
                  onClick={() => removeTeam(team.uuid)}
                >
                  <div>
                    <IconDelete width="13" height="13" /> {t(`${I18N_PREFIX}.removeTeam.title`)}
                  </div>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <StyledButton
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              icon={<Icon3Dots width="15" height="15" />}
              shape="circle"
              type="text"
            />
          </Dropdown>
          <PlayerName>{championshipEntity.championshipTeam.team.name}</PlayerName>
        </>
      ),
      className: 'team-column',
    },
    {
      title: t(`${I18N_PREFIX}.players`),
      dataIndex: 'championshipMatchEntityPlayers',
      render: (championshipMatchEntityPlayers: ChampionshipMatchEntityPlayer[]) =>
        championshipMatchEntityPlayers.map((championshipMatchEntityPlayer) => (
          <PlayerWrapper key={championshipMatchEntityPlayer.uuid}>
            <PlayerName>
              {championshipMatchEntityPlayer.championshipEntityPlayer.player.firstName}{' '}
              {championshipMatchEntityPlayer.championshipEntityPlayer.player.lastName}
            </PlayerName>
          </PlayerWrapper>
        )),
    },
    {
      title: t(`${I18N_PREFIX}.identification`),
      dataIndex: 'championshipMatchEntityPlayers',
      render: (championshipMatchEntityPlayers: ChampionshipMatchEntityPlayer[]) =>
        championshipMatchEntityPlayers.map((championshipMatchEntityPlayer) => (
          <PlayerWrapper key={championshipMatchEntityPlayer.uuid}>
            {championshipMatchEntityPlayer.championshipEntityPlayer.player.identification}
          </PlayerWrapper>
        )),
    },
    {
      title: t(`${I18N_PREFIX}.number`),
      dataIndex: 'championshipMatchEntityPlayers',
      render: (championshipMatchEntityPlayers: ChampionshipMatchEntityPlayer[]) =>
        championshipMatchEntityPlayers.map((championshipMatchEntityPlayer) => (
          <PlayerWrapper key={championshipMatchEntityPlayer.uuid}>
            <StyledInput
              disabled={loading}
              defaultValue={championshipMatchEntityPlayer.number || ''}
              onPressEnter={(e) =>
                setNumber(championshipMatchEntityPlayer.championshipEntityPlayer, e)
              }
            />
          </PlayerWrapper>
        )),
    },
    {
      title: '',
      dataIndex: 'championshipMatchEntityPlayers',
      render: (championshipMatchEntityPlayers: ChampionshipMatchEntityPlayer[]) =>
        championshipMatchEntityPlayers.map((championshipMatchEntityPlayer) => (
          <PlayerWrapper key={championshipMatchEntityPlayer.uuid}>
            <Button
              onClick={() => removePlayer(championshipMatchEntityPlayer.uuid)}
              icon={<IconDelete width="13" height="13" />}
              shape="circle"
            />
          </PlayerWrapper>
        )),
    },
  ];

  const list = (teams as ChampionshipMatchEntity[]) || [];

  return (
    <Container>
      <Table
        columns={tableColumns}
        dataSource={list}
        loading={false}
        rowKey="uuid"
        pagination={false}
      />
      <AddTeamButton
        onClick={() => setShowTeamsAddModal(true)}
        icon={<IconPlus width="13" height="13" className="anticon" fill={ColorGrey05} />}
      >
        {t(`${I18N_PREFIX}.addTeam`)}
      </AddTeamButton>
    </Container>
  );
};
