import { useLazyQuery, useMutation } from '@apollo/client';
import { Form } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { ChampionshipMatchForm } from './championshipMatchForm';

import { PageHeader } from 'components/pageHeader';
import { SubmitButton } from 'components/submitButton';

import { GetChampionshipGroup } from 'queries/sportsChampionshipGroup';
import { CreateChampionshipMatch } from 'queries/sportsChampionshipMatch';
import { CreateChampionshipMatchEntities } from 'queries/sportsChampionshipMatchTeams';
import {
  ConfigMatchEntityType,
  Mutation,
  MutationCreateChampionshipMatchArgs,
  MutationCreateChampionshipMatchEntitiesArgs,
  Query,
  QueryChampionshipGroupArgs,
} from 'queries/types';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 12px;
  }
`;

interface Props {
  onFinish: () => void;
}

const I18N_PREFIX = 'sportsMatch.create';

export const ChampionshipMatchCreate = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { t } = useTranslation();

  const { group } = useParams<{ group: string }>();

  const [getChampionshipGroup, { data: championshipGroup }] = useLazyQuery<
    Query,
    QueryChampionshipGroupArgs
  >(GetChampionshipGroup, {
    variables: {
      uuid: group,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (group) getChampionshipGroup();
  }, []);

  const [addTeamsToChampionshipMatch, { loading: isTeamAdding, data: isTeamAdded }] = useMutation<
    Mutation,
    MutationCreateChampionshipMatchEntitiesArgs
  >(CreateChampionshipMatchEntities);

  useEffect(() => {
    if (isTeamAdded) {
      redirectToMatch();
    }
  }, [isTeamAdded]);

  const [createChampionshipMatch, { loading: isAdding, data: isAdded }] = useMutation<
    Mutation,
    MutationCreateChampionshipMatchArgs
  >(CreateChampionshipMatch);

  useEffect(() => {
    if (isAdded && isAdded.createChampionshipMatch.uuid) {
      const championshipEntityUuids: string[] = Object.keys(form.getFieldsValue()).reduce(
        (previous, current) =>
          current.includes('team') && form.getFieldValue(current)
            ? [...previous, form.getFieldValue(current) as any]
            : previous,
        [] as string[]
      );

      if (championshipEntityUuids.length) {
        addTeamsToChampionshipMatch({
          variables: {
            championshipMatchUuid: isAdded.createChampionshipMatch.uuid,
            championshipEntityUuids: championshipEntityUuids,
          },
        }).catch((error) => {
          console.error(error);
        });
      } else {
        redirectToMatch();
      }
    }
  }, [isAdded]);

  const configMatchEntityType =
    championshipGroup?.championshipGroup.championshipStage.championshipCompetition.championshipSport
      .configMatchEntityType;

  const configMatchEntityMaxNumber =
    configMatchEntityType === ConfigMatchEntityType.Pair
      ? 2
      : championshipGroup?.championshipGroup.championshipStage.championshipCompetition
          .championshipSport.configMatchEntityMaxNumber;

  const redirectToMatch = () => {
    history.push(`${ROUTES.SportsMatch}/${isAdded?.createChampionshipMatch.uuid}/summary`);
    window.location.reload();
  };

  const onFinish = async (values: any) => {
    createChampionshipMatch({
      variables: {
        input: {
          datetime: values.datetime,
          location: {
            pt: values.location,
          },
          championshipGroup: group,
        },
      },
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <Form onFinish={onFinish} layout="vertical" form={form}>
      <StyledPageHeader title={'Criar Jogo'}>
        <ButtonsWrapper>
          <SubmitButton isLoading={isAdding || isTeamAdding} label={t(`${I18N_PREFIX}.submit`)} />
        </ButtonsWrapper>
      </StyledPageHeader>

      <ChampionshipMatchForm
        form={form}
        championshipGroup={championshipGroup?.championshipGroup}
        configMatchEntityType={configMatchEntityType}
        configMatchEntityMaxNumber={configMatchEntityMaxNumber}
      />
    </Form>
  );
};
