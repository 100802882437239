import { useLazyQuery } from '@apollo/client';
import { Card, Table, Button, Dropdown, Menu } from 'antd';
import { ITEMS_PER_PAGE } from 'config/main';
import { getTablePagination } from 'helpers/tablePagination';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { Icon3Dots } from 'assets/icons/icon3Dots';
import { IconSettings } from 'assets/icons/iconSettings';

import { GetChampionships } from 'queries/sportsChampionships';
import {
  Query,
  Pagination,
  QueryChampionshipsArgs,
  Championship,
  ManagementEntity,
  Scalars,
} from 'queries/types';

const LogoTitleWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  margin-right: 20px;
  overflow: hidden;
  line-height: 0;
  background-color: black;
  padding: 3px;
  border-radius: 50%;

  img {
    width: 18px;
  }
`;

const I18N_PREFIX: string = 'sportsChampionshipsList';

export const SportsChampionshipsList: React.VFC = () => {
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const { t } = useTranslation();

  const variables: QueryChampionshipsArgs = {
    pagination: {
      page: selectedPage,
      take: ITEMS_PER_PAGE,
    },
  };

  const [getChampionships, { data, loading }] = useLazyQuery<Query, QueryChampionshipsArgs>(
    GetChampionships,
    {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    getChampionships();
  }, [getChampionships]);

  const tableColumns = [
    {
      title: t(`${I18N_PREFIX}.name`),
      dataIndex: 'name',
      render: (name: Scalars['TranslatableField'], championship: Championship) => (
        <LogoTitleWrapper>
          <LogoWrapper>
            {championship.logo?.url && <img src={championship.logo?.url} alt={name.pt} />}
          </LogoWrapper>
          <Link to={`${ROUTES.SportsChampionships}/${championship.uuid}`}>
            <span className="font-weight-bold">{name.pt}</span>
          </Link>
        </LogoTitleWrapper>
      ),
      width: '40%',
    },
    {
      title: t(`${I18N_PREFIX}.shortName`),
      dataIndex: 'shortName',
      render: (shortName: Scalars['TranslatableField']) => <span>{shortName.pt}</span>,
      width: '30%',
    },
    {
      title: t(`${I18N_PREFIX}.managementEntity`),
      dataIndex: 'managementEntity',
      render: (managementEntity: ManagementEntity) => <span>{managementEntity.name.pt}</span>,
      width: '30%',
    },
    {
      title: '',
      dataIndex: 'uuid',
      render: (uuid: string) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key={`championships-list-edit-${uuid}`}>
                <Link to={`${ROUTES.SportsChampionships}/edit/${uuid}`}>
                  <IconSettings width="12px" height="12px" fill="black" /> Editar
                </Link>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            icon={<Icon3Dots width="15" height="15" />}
            shape="circle"
            type="text"
          ></Button>
        </Dropdown>
      ),
      width: '1%',
    },
  ];

  const list = (data?.championships.edges as Championship[]) || [];
  const pagination = data?.championships.pagination as Pagination;

  return (
    <Card>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={list}
          loading={loading}
          rowKey="uuid"
          pagination={getTablePagination(pagination, setSelectedPage)}
        />
      </div>
    </Card>
  );
};
