import { gql } from '@apollo/client';

export const GetPageCategory = gql`
  query GetPageCategory($uuid: UUID!) {
    pageCategory(uuid: $uuid) {
      uuid
      title
      externalUrl
      page {
        uuid
        title
      }
      parentPageCategory {
        uuid
        title
      }
    }
  }
`;

export const GetWebsiteCategories = gql`
  query GetWebsiteCategories($filter: InputPageCategoriesFilter) {
    pageCategories(filter: $filter) {
      edges {
        uuid
        title
        externalUrl
        page {
          uuid
          title
        }
        pageCategories {
          uuid
          title
          pageCategories {
            uuid
            title
          }
        }
      }
    }
  }
`;

export const GetAllWebsiteCategories = gql`
  query GetAllWebsiteCategories {
    pageCategories {
      edges {
        uuid
        title
      }
    }
  }
`;

export const CreateWebsiteCategory = gql`
  mutation CreateWebsiteCategory($input: InputCreatePageCategory!) {
    createPageCategory(input: $input) {
      uuid
    }
  }
`;

export const UpdateWebsiteCategory = gql`
  mutation UpdateWebsiteCategory($uuid: UUID!, $input: InputUpdatePageCategory!) {
    updatePageCategory(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const DeleteWebsiteCategory = gql`
  mutation DeleteWebsiteCategory($uuid: UUID!) {
    deletePageCategory(uuid: $uuid) {
      uuid
    }
  }
`;
