import { Table, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ListActionElement, ListActionElementType } from 'components/listActionElement';

import { IconDelete } from 'assets/icons/iconDelete';
import { IconPlus } from 'assets/icons/iconPlus';

import {
  ChampionshipEntityPlayer,
  ChampionshipMatchEntity,
  ChampionshipMatchEntityPlayer,
  ChampionshipMatchEntityPlayerType,
} from 'queries/types';

const TeamWrapper = styled.div`
  background: white;
  padding: 20px;
  border: 1px solid #edf2f9;
  border-radius: 10px;

  &:first-of-type {
    margin-right: 8px;
  }

  &:last-of-type {
    margin-left: 8px;
  }
`;

const TeamHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledH4 = styled.h4`
  font-weight: bold;
`;

const PlayerName = styled.span`
  font-weight: bold;
`;

const StyledInput = styled(Input)`
  text-align: center;
  width: 40px;
`;

interface Props {
  teams: ChampionshipMatchEntity[];
  loading: boolean;
  setNumber: any;
  setSubstitute: any;
  removePlayer: any;
  setTeamToAdd: any;
  setShowPlayerAddModal: any;
}

const I18N_PREFIX = 'sportsMatch.sections.teams';

export const TeamsPair = ({
  teams,
  loading,
  setNumber,
  setSubstitute,
  removePlayer,
  setTeamToAdd,
  setShowPlayerAddModal,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {teams?.map((team: ChampionshipMatchEntity) => {
        const tableColumns = [
          {
            title: t(`${I18N_PREFIX}.name`),
            dataIndex: 'championshipEntityPlayer',
            render: (championshipEntityPlayer: ChampionshipEntityPlayer) => (
              <PlayerName>
                {championshipEntityPlayer.player.firstName}{' '}
                {championshipEntityPlayer.player.lastName}
              </PlayerName>
            ),
            width: '50%',
          },
          {
            title: t(`${I18N_PREFIX}.identification`),
            dataIndex: 'championshipEntityPlayer',
            render: (championshipEntityPlayer: ChampionshipEntityPlayer) =>
              championshipEntityPlayer.player.identification,
          },
          {
            title: t(`${I18N_PREFIX}.number`),
            dataIndex: 'championshipEntityPlayer',
            render: (championshipEntityPlayer: ChampionshipEntityPlayer) => (
              <StyledInput
                disabled={loading}
                defaultValue={
                  (championshipEntityPlayer.championshipMatchEntityPlayers &&
                    championshipEntityPlayer.championshipMatchEntityPlayers[0]?.number) ||
                  ''
                }
                onPressEnter={(e) => setNumber(championshipEntityPlayer, e)}
              />
            ),
          },
          {
            title: t(`${I18N_PREFIX}.isSubstitute`),
            dataIndex: 'championshipEntityPlayer',
            render: (championshipEntityPlayer: ChampionshipEntityPlayer) => (
              <ListActionElement
                type={ListActionElementType.Toggle}
                action={() => setSubstitute(championshipEntityPlayer)}
                disabled={loading}
                checked={
                  championshipEntityPlayer.championshipMatchEntityPlayers &&
                  championshipEntityPlayer.championshipMatchEntityPlayers[0]?.type ===
                    ChampionshipMatchEntityPlayerType.PlayerSubstitute
                }
              />
            ),
          },
          {
            title: '',
            dataIndex: 'championshipEntityPlayer',
            render: (championshipEntityPlayer: ChampionshipEntityPlayer) => (
              <Button
                onClick={() =>
                  removePlayer(
                    (championshipEntityPlayer.championshipMatchEntityPlayers &&
                      championshipEntityPlayer.championshipMatchEntityPlayers[0]?.uuid) ||
                      ''
                  )
                }
                icon={<IconDelete width="13" height="13" />}
                shape="circle"
              />
            ),
          },
        ];

        const list = (team.championshipMatchEntityPlayers as ChampionshipMatchEntityPlayer[]) || [];

        return (
          <TeamWrapper key={team.uuid}>
            <TeamHeader>
              <StyledH4>{team.championshipEntity.championshipTeam.team.name}</StyledH4>
              <Button
                onClick={() => {
                  setTeamToAdd(team);
                  setShowPlayerAddModal(true);
                }}
                type="primary"
                icon={<IconPlus width="15" height="15" />}
                shape="circle"
              />
            </TeamHeader>
            <Table
              columns={tableColumns}
              dataSource={list}
              loading={false}
              rowKey="uuid"
              pagination={false}
            />
          </TeamWrapper>
        );
      })}
    </>
  );
};
