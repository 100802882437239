import { useMutation } from '@apollo/client';
import { Button, Table } from 'antd';
import { ColorGrey04, ColorMain } from 'config/main';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { convertScoreForRender, convertScoreForResponse, getInputMaskByUnit } from './utils';

import { IconDownArrow } from 'assets/icons/iconDownArrow';
import { IconUpArrow } from 'assets/icons/iconUpArrow';

import { UpdateChampionshipMatchEntities } from 'queries/sportsChampionshipMatchTeams';
import {
  ChampionshipMatchEntity,
  ChampionshipMatchEntityPlayer,
  ChampionshipSport,
  Mutation,
  MutationUpdateChampionshipMatchEntitiesArgs,
} from 'queries/types';

const Container = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 0 25px 25px 25px;
  margin-left: -25px;
  background: white;
  width: calc(100% + 50px);
  border-top: 1px solid ${ColorMain};
  border-bottom: 1px solid ${ColorMain};
`;

const ClassificationTeam = styled.span`
  color: ${ColorGrey04};
  display: inline-block;
  margin-right: 20px;
`;

const PlayerDetails = styled.span`
  line-height: 40px;
  display: inline-block;
`;

const ButtonWrapper = styled.div`
  display: fleX;
`;

const StyledButton = styled(Button)`
  width: 40px;
`;

const StyledInputMask = styled(InputMask)`
  width: 150px;
`;

const MatchScoreContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const MatchScoreUnit = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: ${ColorGrey04};
`;

interface Props {
  teams: ChampionshipMatchEntity[];
  loading: boolean;
  refetch: any;
  championshipSport: ChampionshipSport;
}

export const getTeamsByScores = (teams: ChampionshipMatchEntity[]) => {
  let scores: any = [];
  [...teams].forEach(
    (team: any) => (scores[team.matchScore] = [...(scores[team.matchScore] || []), team])
  );

  return scores;
};

export const HeaderTeamGrid = ({ teams, loading, refetch, championshipSport }: Props) => {
  const { t } = useTranslation();
  const [matchScores, setMatchScores] = useState([]);

  const { showScoreUnit, scoreUnit } = championshipSport;

  const teamsSortedByScores = [...teams].sort((first, second) => {
    return (
      (second.matchScore || 0) - (first.matchScore || 0) ||
      (second.drawScore || 0) - (first.drawScore || 0)
    );
  }) as ChampionshipMatchEntity[];

  const teamsByScores = getTeamsByScores(teamsSortedByScores);

  const [updateChampionshipMatchEntities, { loading: isUpdating }] = useMutation<
    Mutation,
    MutationUpdateChampionshipMatchEntitiesArgs
  >(UpdateChampionshipMatchEntities, refetch);

  const setEntityWeight = (direction: 'up' | 'down', team: ChampionshipMatchEntity) => {
    let drawScore = 0;

    const index = teamsByScores[team.matchScore || 0].indexOf(team);

    if (direction === 'up') {
      drawScore = teamsByScores[team.matchScore || 0][index - 1].drawScore + 1;
    } else {
      drawScore = teamsByScores[team.matchScore || 0][index + 1].drawScore - 1;
    }

    updateChampionshipMatchEntities({
      variables: {
        championshipMatchUuid: team.championshipMatch.uuid,
        championshipMatchEntities: [
          {
            uuid: team.uuid,
            drawScore,
          },
        ],
      },
    });
  };

  const updateMatchEntitiesScore = (
    team: ChampionshipMatchEntity,
    matchScore: number,
    value: number
  ) => {
    if (matchScore !== value) {
      updateChampionshipMatchEntities({
        variables: {
          championshipMatchUuid: team.championshipMatch.uuid,
          championshipMatchEntities: [
            {
              uuid: team.uuid,
              matchScore: value,
            },
          ],
        },
      });
    }
  };

  const tableColumns = [
    {
      dataIndex: 'uuid',
      render: (_uuid: string, _team: ChampionshipMatchEntity, index: number) => (
        <PlayerDetails>
          <ClassificationTeam>{index + 1}º </ClassificationTeam>
        </PlayerDetails>
      ),
      width: '5%',
    },
    {
      dataIndex: 'championshipMatchEntityPlayers',
      render: (
        championshipMatchEntityPlayers: ChampionshipMatchEntityPlayer[],
        team: ChampionshipMatchEntity
      ) => {
        return (
          <PlayerDetails>
            <ClassificationTeam>
              {team.championshipEntity.championshipTeam.team.name}
            </ClassificationTeam>

            {championshipMatchEntityPlayers.map((championshipMatchEntityPlayer, key) => (
              <span key={championshipMatchEntityPlayer.uuid}>
                {key !== 0 && <>, </>}
                {championshipMatchEntityPlayer.championshipEntityPlayer.player.firstName}{' '}
                {championshipMatchEntityPlayer.championshipEntityPlayer.player.lastName.charAt(0)}.
              </span>
            ))}
          </PlayerDetails>
        );
      },
    },
    {
      dataIndex: 'matchScore',
      render: (matchScore: number, team: ChampionshipMatchEntity) => (
        <>
          {matchScore && teamsByScores[matchScore].length > 1 ? (
            <ButtonWrapper>
              {teamsByScores[matchScore][0] !== team ? (
                <StyledButton
                  type="text"
                  shape="circle"
                  disabled={loading || isUpdating}
                  onClick={() => setEntityWeight('up', team)}
                >
                  <IconUpArrow width="20" height="20" />
                </StyledButton>
              ) : (
                <StyledButton disabled />
              )}
              {teamsByScores[matchScore][teamsByScores[matchScore].length - 1] !== team ? (
                <StyledButton
                  type="text"
                  shape="circle"
                  disabled={loading || isUpdating}
                  onClick={() => setEntityWeight('down', team)}
                >
                  <IconDownArrow width="20" height="20" />
                </StyledButton>
              ) : (
                <StyledButton disabled />
              )}
            </ButtonWrapper>
          ) : null}
        </>
      ),
    },
    {
      dataIndex: 'matchScore',
      render: (matchScore: number, team: ChampionshipMatchEntity) => (
        <MatchScoreContainer>
          <StyledInputMask
            className="ant-input"
            mask={getInputMaskByUnit(showScoreUnit || scoreUnit)}
            onKeyPress={(e: any) =>
              e.key === 'Enter'
                ? updateMatchEntitiesScore(
                    team,
                    matchScore,
                    parseFloat(
                      convertScoreForResponse(
                        e.target.value,
                        showScoreUnit || scoreUnit,
                        scoreUnit || showScoreUnit
                      )
                    )
                  )
                : undefined
            }
            onBlur={(e: any) =>
              updateMatchEntitiesScore(
                team,
                matchScore,
                parseFloat(
                  convertScoreForResponse(
                    e.target.value,
                    showScoreUnit || scoreUnit,
                    scoreUnit || showScoreUnit
                  )
                )
              )
            }
            disabled={loading || isUpdating}
            value={
              matchScores[team.uuid as any] ||
              convertScoreForRender(
                matchScore || 0,
                scoreUnit || showScoreUnit,
                showScoreUnit || scoreUnit
              )
            }
            onChange={(e) => setMatchScores({ [team.uuid]: e.target.value } as any)}
          />
          <MatchScoreUnit>{t(showScoreUnit || scoreUnit)}</MatchScoreUnit>
        </MatchScoreContainer>
      ),
      width: '10%',
    },
  ];

  const list = (teamsSortedByScores as ChampionshipMatchEntity[]) || [];

  return (
    <Container>
      <Table
        columns={tableColumns}
        dataSource={list}
        loading={false}
        rowKey="uuid"
        pagination={false}
      />
    </Container>
  );
};
