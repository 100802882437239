import { IconProps } from './type';

export const IconPages = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1035 16.5C5.7945 16.5 5.50125 16.476 5.22525 16.4288L5.06175 16.398C3.2835 16.0283 2.25 14.6663 2.25 12.5933V5.40675C2.25 5.15625 2.265 4.91625 2.295 4.68675C2.55675 2.673 3.9405 1.5 6.1035 1.5H11.8973C14.2943 1.5 15.7275 2.94 15.75 5.349V5.40675V12.5933C15.75 15.036 14.313 16.5 11.8973 16.5H6.1035ZM6.1035 2.59275C4.2615 2.59275 3.32775 3.54 3.32775 5.40675V12.5933C3.32775 14.46 4.2615 15.4073 6.1035 15.4073H11.8905C13.7318 15.4073 14.6595 14.46 14.6595 12.5933V5.40675C14.6595 3.54 13.7318 2.59275 11.8905 2.59275H6.1035ZM12.2295 12.1755C12.2295 11.8755 11.9903 11.6325 11.6948 11.6325H6.2865C5.991 11.6325 5.751 11.8755 5.751 12.1755C5.751 12.4755 5.991 12.7185 6.2865 12.7185H11.6948C11.9903 12.7185 12.2295 12.4755 12.2295 12.1755ZM11.6947 8.45325C11.901 8.43075 12.1027 8.52975 12.213 8.7075C12.3232 8.88675 12.3232 9.11325 12.213 9.2925C12.1027 9.47025 11.901 9.56925 11.6947 9.54675H6.2865C6.012 9.51525 5.80425 9.2805 5.80425 9C5.80425 8.7195 6.012 8.484 6.2865 8.45325H11.6947ZM8.83275 5.82825C8.83275 5.54775 8.625 5.31225 8.3505 5.2815H6.29325C6.018 5.31225 5.81025 5.54775 5.81025 5.82825C5.81025 6.108 6.018 6.3435 6.29325 6.37425H8.3505C8.625 6.3435 8.83275 6.108 8.83275 5.82825Z"
        fill={fill || 'black'}
      />
    </svg>
  );
};
