import { useMutation, useLazyQuery } from '@apollo/client';
import { Card, Table } from 'antd';
import { ITEMS_PER_PAGE } from 'config/main';
import { getTablePagination } from 'helpers/tablePagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/config';

import { ListActionElement, ListActionElementType } from 'components/listActionElement';
import { Tag } from 'components/tag';

import {
  Category,
  Query,
  Scalars,
  Mutation,
  Pagination,
  FeedPostOrderKeys,
  QueryPagesArgs,
  Page,
  MutationUpdatePageArgs,
} from 'queries/types';
import { GetWebsitePagesPaginated, UpdateWebsitePage } from 'queries/websitePages';

/**
 * STYLES SECTION
 */

/**
 * CONSTANTS
 */
const I18N_PREFIX: string = 'website.pages';
const DEFAULT_ORDER_KEY = FeedPostOrderKeys.CreatedAt;

/**
 * INTERFACES
 */
interface Props {}

export const WebsitePagesList: React.VFC<Props> = () => {
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const { t } = useTranslation();

  const variables: QueryPagesArgs = {
    pagination: {
      page: selectedPage,
      take: ITEMS_PER_PAGE,
    },
  };

  const [getWebsitePages, { data, loading }] = useLazyQuery<Query, QueryPagesArgs>(
    GetWebsitePagesPaginated,
    {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    getWebsitePages();
  }, [getWebsitePages]);

  const pages = (data?.pages.edges as Page[]) || [];
  const pagination = data?.pages.pagination as Pagination;

  const [setPublished, { loading: updateLoading }] = useMutation<Mutation, MutationUpdatePageArgs>(
    UpdateWebsitePage,
    {
      refetchQueries: [
        {
          query: GetWebsitePagesPaginated,
          variables,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const setPublishedPage = (page: Page, value: boolean) => {
    setPublished({
      variables: {
        uuid: page.uuid,
        input: {
          slug: page.slug,
          title: page.title,
          blocks: page.blocks,
          publishedAt: value ? new Date().toISOString() : null,
        },
      },
    });
  };

  const tableColumns = [
    {
      title: t(`${I18N_PREFIX}.title`),
      dataIndex: 'title',
      render: (text: Scalars['TranslatableField'], page: Page) => (
        <span className="font-weight-bold">
          <Link to={`${ROUTES.WebsitePages}/${page.uuid}`}>{text.pt}</Link>
        </span>
      ),
      width: '50%',
    },
    {
      title: t(`${I18N_PREFIX}.category`),
      dataIndex: 'category',
      render: (category: Category) => category && <Tag>{category?.name.pt || ''}</Tag>,
    },
    {
      title: t(`${I18N_PREFIX}.${DEFAULT_ORDER_KEY}`),
      dataIndex: DEFAULT_ORDER_KEY,
      render: (date: string) => <span>{moment(date).format('DD/MM/YYYY')}</span>,
    },
    {
      action: '',
      dataIndex: 'publishedAt',
      title: t(`${I18N_PREFIX}.publishedAt`),
      render: (publishedAt: Page['publishedAt'], page: Page) => (
        <ListActionElement
          type={ListActionElementType.Toggle}
          action={(value: boolean) => setPublishedPage(page, value)}
          checked={publishedAt}
        >
          {t('actions.hide')}
        </ListActionElement>
      ),
    },
  ];

  return (
    <Card>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={pages}
          loading={loading || updateLoading}
          rowKey="uuid"
          pagination={getTablePagination(pagination, setSelectedPage)}
        />
      </div>
    </Card>
  );
};
