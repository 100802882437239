import { gql } from '@apollo/client';

// export const AddTeamsToChampionshipMatch = gql`
//   mutation AddTeamsToChampionshipMatch($uuid: UUID!, $teamUuids: [UUID]!) {
//     addTeamsToChampionshipMatch(uuid: $uuid, teamUuids: $teamUuids) {
//       uuid
//     }
//   }
// `;

export const CreateChampionshipMatchEntities = gql`
  mutation CreateChampionshipMatchEntities(
    $championshipMatchUuid: UUID!
    $championshipEntityUuids: [UUID]!
  ) {
    createChampionshipMatchEntities(
      championshipMatchUuid: $championshipMatchUuid
      championshipEntityUuids: $championshipEntityUuids
    ) {
      uuid
    }
  }
`;

export const UpdateChampionshipMatchEntities = gql`
  mutation UpdateChampionshipMatchEntities(
    $championshipMatchUuid: UUID!
    $championshipMatchEntities: [InputChampionshipMatchEntities]!
  ) {
    updateChampionshipMatchEntities(
      championshipMatchUuid: $championshipMatchUuid
      championshipMatchEntities: $championshipMatchEntities
    ) {
      uuid
    }
  }
`;

export const DeleteChampionshipMatchEntity = gql`
  mutation DeleteChampionshipMatchEntity($uuid: UUID!) {
    deleteChampionshipMatchEntity(uuid: $uuid) {
      uuid
    }
  }
`;
