import Delimiter from '@editorjs/delimiter';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Table from '@editorjs/table';

import Document from './custom/document';
import Gallery from './custom/gallery';
import Image from './custom/image';
import Link from './custom/link';
import Video from './custom/video';

export const EDITOR_JS_TOOLS = {
  header: Header,
  list: List,
  delimiter: Delimiter,
  image: Image,
  video: Video,
  gallery: Gallery,
};

export const EDITOR_JS_TOOLS_VIDEO = {
  video: Video as any,
};

export const EDITOR_JS_TOOLS_GALLERY = {
  gallery: Gallery as any,
};

export const EDITOR_JS_TOOLS_MEDIA = {
  video: Video as any,
  image: Image as any,
};

export const EDITOR_JS_TOOLS_PAGES = {
  ...EDITOR_JS_TOOLS,
  link: Link,
  document: Document,
  table: Table,
};

export const removeTableContentEditable = (blocks: any) => {
  return blocks.map((block: any) => {
    if (block.type === 'table') {
      block.data.content.forEach((blockLines: any, index: number) => {
        blockLines.forEach((blockLine: string, index: number) => {
          blockLines[index] = blockLine.replace('contenteditable="true"', '');
        });
      });
      return block;
    }
    return block;
  });
};
