import { useQuery } from '@apollo/client';
import { Button, Menu, Modal, Table } from 'antd';
import { MODAL_ITEMS_PER_PAGE } from 'config/main';
import { getTablePagination } from 'helpers/tablePagination';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ModalTabButton } from 'typings/tabs';

import { commonColumns, socialColumns } from './columns';
import { tabsMenu } from './config';

import { GetPostListQuery } from 'queries/postsList';
import {
  FeedPost,
  FeedPostOrderKeys,
  FeedPostPublishedType,
  OrderDirection,
  Pagination,
  Query,
  QueryFeedPostsArgs,
} from 'queries/types';

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

interface HighlightsModalProps {
  isAdding: boolean;
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  onAddConfirm: (uuid: string) => void;
}

export const HighlightsModal = ({
  isAdding,
  showModal,
  setShowModal,
  onAddConfirm,
}: HighlightsModalProps) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [selectedMenu, setSelectedMenu] = useState<string>('articles');

  const itemConfig = tabsMenu.find((item) => item.key === selectedMenu);

  const variables: QueryFeedPostsArgs = {
    filter: {
      type: itemConfig?.type || [],
      origin: itemConfig?.origin || [],
      highlighted: false,
      published: [FeedPostPublishedType.App],
    },
    order: { key: FeedPostOrderKeys.PublishedAt, direction: OrderDirection.Desc },
    pagination: {
      page: selectedPage,
      take: MODAL_ITEMS_PER_PAGE,
    },
  };

  const { loading, data, refetch } = useQuery<Query, QueryFeedPostsArgs>(GetPostListQuery, {
    variables,
    skip: variables.filter?.type?.length === 0 && variables.filter.origin?.length === 0,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (showModal) {
      refetch();
    } else {
      resetModal();
    }
  }, [showModal]);

  const list = (data?.feedPosts.edges as FeedPost[]) || [];
  const pagination = data?.feedPosts.pagination as Pagination;

  const tableColumns = useMemo(() => {
    if (itemConfig?.key === 'social') {
      return socialColumns;
    } else {
      return commonColumns;
    }
  }, [itemConfig]);

  /**
   * Resets the modal state
   */
  const resetModal = useCallback(() => {
    setSelectedPage(1);
    setSelectedRowKeys([]);
    setSelectedMenu(tabsMenu[0].key);
  }, []);

  /**
   * Handle ok, calls the onAddConfirm method
   */
  const handleOk = useCallback(() => {
    onAddConfirm(selectedRowKeys[0]);
  }, [selectedRowKeys, onAddConfirm]);

  /**
   * Handle cancel, closes modal
   */
  const handleCancel = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <Modal
      width="960px"
      title={t('highlights.addHighlight', { count: selectedRowKeys.length })}
      visible={showModal}
      onCancel={handleCancel}
      centered={true}
      footer={[
        <StyledButton
          key="submit"
          type="primary"
          loading={isAdding}
          disabled={selectedRowKeys.length === 0}
          onClick={handleOk}
        >
          {t('highlights.addHighlight')}
        </StyledButton>,
      ]}
      bodyStyle={{
        paddingTop: 0,
      }}
    >
      <Menu theme="light" mode="horizontal" selectedKeys={[selectedMenu]}>
        {tabsMenu.map((item: ModalTabButton) => (
          <Menu.Item
            key={`highlight-modal-${item.key}`}
            onClick={() => {
              setSelectedRowKeys([]);
              setSelectedMenu(item.key);
            }}
          >
            <span>{item.title}</span>
          </Menu.Item>
        ))}
      </Menu>

      <div className="table-responsive">
        <Table
          loading={loading}
          columns={tableColumns}
          dataSource={list}
          rowKey="uuid"
          pagination={getTablePagination(pagination, setSelectedPage, MODAL_ITEMS_PER_PAGE)}
          showHeader={false}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            type: 'radio',
            preserveSelectedRowKeys: false,
            onChange: (selectedKeys: any) => {
              setSelectedRowKeys(selectedKeys);
            },
          }}
        />
      </div>
    </Modal>
  );
};
