import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SingleEvent } from './singleEvent';

import {
  ChampionshipMatchEntity,
  ChampionshipMatchesClock,
  ChampionshipMatchEvent,
  ClockStatus,
} from 'queries/types';

const Container = styled.ul`
  position: relative;
  margin: 43px 0;
  padding: 10px 0;
  list-style: none;
  display: flex;
  flex-direction: column;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background: #9fa8b7;
  }
`;

interface Props {
  events: ChampionshipMatchEvent[];
  clock: ChampionshipMatchesClock;
  status: ClockStatus;
  isMatchGoing: boolean;
  areEventsOnLastPart: boolean;
  refetch: any;
}

export const Events = ({
  events,
  clock,
  status,
  isMatchGoing,
  areEventsOnLastPart,
  refetch,
}: Props) => {
  const { t } = useTranslation();

  if (events.length === 0) return null;

  const generateEndPartScore = () => {
    const matchEntities = (clock.championshipMatchEntities || []) as ChampionshipMatchEntity[];

    const mapedMatchEntities = matchEntities.map(
      (entity: ChampionshipMatchEntity, entityIndex: number) => {
        const scores = [entity.matchScore || ''];

        if (entity.drawScore) {
          scores.push(`(${entity.drawScore})`);
        }
        if (entity.clockScore && entity.clockScore.length > 0) {
          scores.push(`(${entity.clockScore[entity.clockScore.length - 1]})`);
        }
        if (entityIndex > 0) {
          scores.reverse().join(' ');
        }
        return scores.join(' ');
      }
    );

    return mapedMatchEntities;
  };

  const partTitle = clock.title.pt;
  const scores = generateEndPartScore();

  return (
    <Container>
      <SingleEvent
        main={true}
        event={
          {
            title: `${t('sportsMatch.sections.summary.event.begin')} ${partTitle}`,
          } as ChampionshipMatchEvent
        }
        canBeDeleted={false}
        refetchEvents={refetch}
      />
      {events.map((event: ChampionshipMatchEvent, key) => (
        <SingleEvent
          key={event.uuid}
          event={event}
          canBeDeleted={isMatchGoing && areEventsOnLastPart && key === events.length - 1}
          refetchEvents={refetch}
        />
      ))}
      {status === ClockStatus.Stop && (
        <SingleEvent
          main={true}
          event={
            {
              title: `${t('sportsMatch.sections.summary.event.end')} ${partTitle}`,
              subtitle: scores && scores.join(' : '),
            } as ChampionshipMatchEvent
          }
          canBeDeleted={false}
          refetchEvents={refetch}
        />
      )}
    </Container>
  );
};
