import { useLazyQuery } from '@apollo/client';
import { Card, Table } from 'antd';
import { ITEMS_PER_PAGE } from 'config/main';
import { getTablePagination } from 'helpers/tablePagination';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { SearchField } from 'components/searchField';

import { GetTeams } from 'queries/sportsTeamsList';
import { Query, Pagination, QueryTeamsArgs, Team, GenericOrderKeys } from 'queries/types';

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
`;

const ColorsWrapper = styled.span`
  display: flex;
`;

const Color = styled.span`
  width: 10px;
  height: 5px;
  display: inline-block;
`;

const I18N_PREFIX: string = 'sportsTeamsList';

interface Props {
  section?: string;
}

export const SportsTeamsList: React.VFC<Props> = ({ section }: Props) => {
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { t } = useTranslation();

  const variables: QueryTeamsArgs = {
    filter: {
      query: searchQuery,
    },
    pagination: {
      page: selectedPage,
      take: ITEMS_PER_PAGE,
    },
  };

  const [getTeams, { data, loading }] = useLazyQuery<Query, QueryTeamsArgs>(GetTeams, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getTeams();
  }, [getTeams]);

  const onSearch = (e: any) => {
    const value = e.currentTarget.value;
    setSearchQuery(value);

    // reset pagination
    setSelectedPage(1);
  };

  const tableColumns = [
    {
      title: '',
      dataIndex: 'colors',
      render: (colors: string[]) => (
        <ColorsWrapper>
          {colors.map((color, key) => (
            <Color key={`${color} ${key}`} style={{ backgroundColor: color }} />
          ))}
        </ColorsWrapper>
      ),
      width: '3%',
    },
    {
      title: t(`${I18N_PREFIX}.name`),
      dataIndex: 'name',
      render: (text: string, team: Team) => (
        <Link to={`${ROUTES.SportsTeams}${ROUTES.Edit}/${team.uuid}`}>
          <span className="font-weight-bold">{text}</span>
        </Link>
      ),
      width: '50%',
    },
    {
      title: t(`${I18N_PREFIX}.${GenericOrderKeys.CreatedAt}`),
      dataIndex: GenericOrderKeys.CreatedAt,
      render: (date: string) => <span>{moment(date).format('DD/MM/YYYY')}</span>,
      width: '20%',
    },
    {
      title: '',
      dataIndex: '',
      render: () => <span></span>,
      width: '1%',
    },
  ];

  const list = (data?.teams.edges as Team[]) || [];
  const pagination = data?.teams.pagination as Pagination;

  return (
    <Card>
      <CardWrapper>
        <div className="mb-1 d-flex">
          <div className="mr-md-3 mb-3">
            <SearchField onChange={onSearch} />
          </div>
        </div>
      </CardWrapper>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={list}
          loading={loading}
          rowKey="uuid"
          pagination={getTablePagination(pagination, setSelectedPage)}
        />
      </div>
    </Card>
  );
};
