import { useLazyQuery, useMutation } from '@apollo/client';
import { Form } from 'antd';
import { ALL_ITEMS_ON_PAGE } from 'config/main';
import { Store } from 'rc-field-form/lib/interface';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { tabsMenu } from './config';
import { SportsChampionshipGroupForm, FormMode } from './sportsChampionshipGroupForm';
import { SportsChampionshipGroupTeams } from './sportsChampionshipGroupTeams';
import { transformFormValues, transformInitialValues } from './utils';

import { PageHeader } from 'components/pageHeader';
import { SubmitButton } from 'components/submitButton';
import { TabsMenu } from 'components/tabsMenu';

import { GetChampionshipGroup } from 'queries/sportsChampionshipGroup';
import { CreateChampionshipGroup, UpdateChampionshipGroup } from 'queries/sportsChampionshipGroups';
import { GetChampionshipStages } from 'queries/sportsChampionshipStages';
import {
  ChampionshipEntity,
  ChampionshipStage,
  Mutation,
  MutationCreateChampionshipGroupArgs,
  MutationUpdateChampionshipGroupArgs,
  Query,
  QueryChampionshipGroupArgs,
  QueryChampionshipStagesArgs,
} from 'queries/types';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TabsMenuWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const I18N_PREFIX = 'sportsChampionshipGroupForm';

export const SportsChampionshipGroupCreateEdit = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();

  const { uuid, competition, stage, group, section } = useParams<{
    uuid: string;
    competition: string;
    stage: string;
    group: string;
    section: string;
  }>();

  const [createChampionshipGroup, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreateChampionshipGroupArgs
  >(CreateChampionshipGroup);

  const [updateChampionshipGroup, { loading: isUpdating, data: isUpdated }] = useMutation<
    Mutation,
    MutationUpdateChampionshipGroupArgs
  >(UpdateChampionshipGroup);

  const [getChampionshipGroup, { data: championshipGroup }] = useLazyQuery<
    Query,
    QueryChampionshipGroupArgs
  >(GetChampionshipGroup, {
    variables: {
      uuid: group,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (group) getChampionshipGroup();
  }, []);

  // define refetch
  const refetch = {
    refetchQueries: [
      {
        query: GetChampionshipGroup,
        variables: {
          uuid: group,
        },
      },
    ],
    awaitRefetchQueries: true,
  };

  useEffect(() => {
    if (isCreated) {
      history.push(`${ROUTES.SportsChampionships}/${uuid}/${competition}/${stage}`);
    }
  }, [isCreated]);

  useEffect(() => {
    if (isUpdated) window.location.reload();
  }, [isUpdated]);

  const onFinish = async (values: any) => {
    if (group) {
      updateChampionshipGroup({
        variables: {
          uuid: group,
          input: transformFormValues(values),
        },
      });
    } else {
      createChampionshipGroup({
        variables: {
          input: {
            ...transformFormValues(values),
          },
        },
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const [getChampionshipStages, { data: championshipStages }] = useLazyQuery<
    Query,
    QueryChampionshipStagesArgs
  >(GetChampionshipStages, {
    variables: {
      filter: {
        championshipCompetitionUuid: competition,
      },
      pagination: {
        page: 1,
        take: ALL_ITEMS_ON_PAGE,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getChampionshipStages();
  }, []);

  if (group && !championshipGroup?.championshipGroup) return null;

  return (
    <Form
      layout="vertical"
      form={form}
      name="sports-championship-form"
      className="sports-championship-create-edit-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={transformInitialValues(stage, championshipGroup?.championshipGroup) as Store}
    >
      <StyledPageHeader
        title={t(group ? `${I18N_PREFIX}.header.edit` : `${I18N_PREFIX}.header.create`)}
        whiteBackground={!!uuid}
      >
        {section !== 'teams' && (
          <ButtonsWrapper>
            <SubmitButton isLoading={isCreating || isUpdating} label={t(`${I18N_PREFIX}.submit`)} />
          </ButtonsWrapper>
        )}
        {uuid && (
          <TabsMenuWrapper>
            <TabsMenu data={tabsMenu({ uuid, competition, stage, group })} />
          </TabsMenuWrapper>
        )}
      </StyledPageHeader>
      {section === 'teams' ? (
        <SportsChampionshipGroupTeams
          teams={championshipGroup?.championshipGroup.championshipEntities as ChampionshipEntity[]}
          refetch={refetch}
        />
      ) : (
        <SportsChampionshipGroupForm
          mode={group ? FormMode.EDIT : FormMode.ADD}
          form={form}
          championshipStages={championshipStages?.championshipStages.edges as ChampionshipStage[]}
          props
        />
      )}
    </Form>
  );
};
