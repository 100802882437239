import { IconProps } from './type';

export const IconCategories = ({ width, height, className, fill }: IconProps) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    width={width}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.01011 1.5H3.73181C2.49817 1.5 1.5 2.50683 1.5 3.74664V6.04472C1.5 7.28402 2.49838 8.29136 3.73181 8.29136H6.01011C7.24353 8.29136 8.24192 7.28402 8.24192 6.04472V3.74664C8.24192 2.50683 7.24375 1.5 6.01011 1.5ZM3.73181 2.54651H6.01011C6.66374 2.54651 7.1954 3.08279 7.1954 3.74664V6.04472C7.1954 6.70813 6.66346 7.24485 6.01011 7.24485H3.73181C3.07846 7.24485 2.54651 6.70813 2.54651 6.04472V3.74664C2.54651 3.08279 3.07817 2.54651 3.73181 2.54651ZM14.2689 1.5H11.9899C10.7563 1.5 9.75808 2.50683 9.75808 3.74664V6.04472C9.75808 7.28402 10.7565 8.29136 11.9899 8.29136H14.2689C15.5018 8.29136 16.5 7.28388 16.5 6.04472V3.74664C16.5 2.50698 15.502 1.5 14.2689 1.5ZM11.9899 2.54651H14.2689C14.9219 2.54651 15.4535 3.08287 15.4535 3.74664V6.04472C15.4535 6.70805 14.9216 7.24485 14.2689 7.24485H11.9899C11.3365 7.24485 10.8046 6.70813 10.8046 6.04472V3.74664C10.8046 3.08279 11.3363 2.54651 11.9899 2.54651ZM3.73181 9.70864H6.01011C7.24389 9.70864 8.24192 10.7156 8.24192 11.956V14.2534C8.24192 15.4932 7.24375 16.5 6.01011 16.5H3.73181C2.49817 16.5 1.5 15.4932 1.5 14.2534V11.956C1.5 10.7156 2.49802 9.70864 3.73181 9.70864ZM6.01011 10.7551H3.73181C3.0781 10.7551 2.54651 11.2915 2.54651 11.956V14.2534C2.54651 14.9172 3.07817 15.4535 3.73181 15.4535H6.01011C6.66374 15.4535 7.1954 14.9172 7.1954 14.2534V11.956C7.1954 11.2915 6.66382 10.7551 6.01011 10.7551ZM14.2689 9.70864H11.9899C10.7561 9.70864 9.75808 10.7156 9.75808 11.956V14.2534C9.75808 15.4932 10.7563 16.5 11.9899 16.5H14.2689C15.502 16.5 16.5 15.493 16.5 14.2534V11.956C16.5 10.7158 15.5021 9.70864 14.2689 9.70864ZM11.9899 10.7551H14.2689C14.922 10.7551 15.4535 11.2916 15.4535 11.956V14.2534C15.4535 14.9171 14.9219 15.4535 14.2689 15.4535H11.9899C11.3363 15.4535 10.8046 14.9172 10.8046 14.2534V11.956C10.8046 11.2915 11.3362 10.7551 11.9899 10.7551Z"
      fill={fill || '#200E32'}
    />
  </svg>
);
