import { Dropdown, Menu, Table, Button } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { Icon3Dots } from 'assets/icons/icon3Dots';
import { IconAngleRight } from 'assets/icons/iconAngleRight';

import { ChampionshipMatch, ChampionshipMatchEntity, ConfigMatchEntityType } from 'queries/types';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const TableContainer = styled.div`
  max-height: calc(100vh - 400px);
  overflow-y: auto;
`;

const Teams = styled.div`
  color: #9fa8b7;

  span {
    font-weight: bold;
    display: block;
  }
`;

const DateTime = styled.span`
  display: inline-block;
  text-align: right;
  color: #9fa8b7;
  font-size: 12px;
  line-height: 15px;
`;

const Time = styled.span`
  display: block;
`;

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 42px;
`;

const ButtonContainer = styled.div`
  padding: 10px 25px;
  border-top: 1px solid #edf2f9;
  border-bottom: 1px solid #edf2f9;
  border-right: 1px solid #edf2f9;
`;

const StyledButton = styled(Button)`
  justify-content: center;
`;

interface Props {
  matches: ChampionshipMatch[] | null | undefined;
}

const I18N_PREFIX = 'sportsChampionship.group';

export const GroupMatches = ({ matches }: Props) => {
  const { t } = useTranslation();
  const { group } = useParams<{ group: string }>();

  const classGroupLive = 'isMatchGroupLive';

  const tableColumns = [
    {
      title: '',
      dataIndex: 'championshipMatchEntities',
      render: (championshipMatchEntities: ChampionshipMatchEntity[], match: ChampionshipMatch) => (
        <Teams className={match.live ? classGroupLive : ''}>
          {championshipMatchEntities &&
            championshipMatchEntities.map((entity) => (
              <span key={entity.uuid}>
                {entity.matchScore !== null || entity.matchScore === 0 ? entity.matchScore : '-'}{' '}
                {entity.drawScore ? `(${entity.drawScore})` : ''}
              </span>
            ))}
        </Teams>
      ),
    },
    {
      title: '',
      dataIndex: 'championshipMatchEntities',
      render: (championshipMatchEntities: ChampionshipMatchEntity[], match: ChampionshipMatch) => (
        <Teams className={match.live ? classGroupLive : ''}>
          {championshipMatchEntities &&
            championshipMatchEntities.map((entity) => (
              <span key={entity.uuid}>
                {entity.championshipEntity.championshipTeam.team.shortName}
              </span>
            ))}
        </Teams>
      ),
    },
    {
      title: '',
      dataIndex: 'datetime',
      render: (datetime: string, match: ChampionshipMatch) => (
        <DateTime>
          {moment(datetime).format('DD/MM/YYYY')}
          <Time className={match.live ? classGroupLive : ''}>
            {match.live ? t(`${I18N_PREFIX}.live`) : moment(datetime).format('HH:mm')}
          </Time>
        </DateTime>
      ),
    },
    {
      title: '',
      dataIndex: 'uuid',
      render: (uuid: string, match: ChampionshipMatch) => {
        if (match.championshipMatchEntities && match.championshipMatchEntities.length == 0) {
          const isSportGrid =
            match.championshipGroup.championshipStage.championshipCompetition.championshipSport
              .configMatchEntityType === ConfigMatchEntityType.Grid;

          if (isSportGrid) return null;

          return (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key={`competitions-group-matches-${match.uuid}`}>
                    <Link to={`${ROUTES.SportsMatch}/${match.uuid}/teams/add`}>
                      Associar Equipas
                    </Link>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                icon={<Icon3Dots width="15" height="15" />}
                shape="circle"
              ></Button>
            </Dropdown>
          );
        }
        return null;
      },
    },
    {
      title: '',
      dataIndex: 'uuid',
      render: (uuid: string, match: ChampionshipMatch) => {
        const isSportGrid =
          match.championshipGroup.championshipStage.championshipCompetition.championshipSport
            .configMatchEntityType === ConfigMatchEntityType.Grid;
        return (
          <StyledLink
            to={
              isSportGrid
                ? `${ROUTES.SportsMatch}/${uuid}/teams`
                : `${ROUTES.SportsMatch}/${uuid}/summary`
            }
          >
            <IconAngleRight width="11" height="8" />
          </StyledLink>
        );
      },
    },
  ];

  const list = (matches as ChampionshipMatch[]) || [];

  return (
    <Container>
      <TableContainer>
        <div className="table-responsive">
          <Table
            columns={tableColumns}
            dataSource={list}
            loading={false}
            rowKey="uuid"
            pagination={false}
            showHeader={false}
          />
        </div>
      </TableContainer>
      <ButtonContainer>
        <Link to={`${ROUTES.SportsMatch}${ROUTES.Create}/${group}`}>
          <StyledButton type="primary" block>
            Adicionar Jogo
          </StyledButton>
        </Link>
      </ButtonContainer>
    </Container>
  );
};
