import { removeTableContentEditable } from 'assets/editorjs/config';

import { InputPage, Page } from 'queries/types';

export const getPagesForOptions = (pages?: Page[]) => {
  if (!pages) return [];

  return pages.map(({ uuid, title }) => ({ uuid, title }));
};

export const transformFormValues = (values: any): InputPage => {
  return {
    slug: values.slug,
    title: { pt: values.title, en: values.title },
    subtitle: {
      pt: values.subtitle,
      en: values.subtitle,
    },
    cover: values.cover,
    blocks: transformBlocks(values.blocks || []),
  };
};

export const transformInitialValues = (data: Page): any => {
  const { slug, title, subtitle, blocks, cover } = data;

  return {
    slug: slug,
    title: title.pt,
    subtitle: subtitle.pt,
    cover: cover,
    blocks: blocks.pt ? blocks.pt.blocks : null,
  };
};

interface BlocksProps {
  time: number;
  blocks: any;
  version: string;
}

const transformBlocks = (blocks: any): { pt: BlocksProps } => {
  return {
    pt: {
      time: new Date().getTime(),
      blocks: removeTableContentEditable(blocks),
      version: '1.9.0',
    },
  };
};
