export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** Supported ContentType String for IMAGE */
  IMAGEContentType: any;
  /** Validate GraphQL Input Image Field */
  InputImageField: any;
  /** Validate GraphQL Input Translatable Blocks Field */
  InputTranslatableBlocks: any;
  /** Validate GraphQL Input Translatable Page Blocks Field */
  InputTranslatablePageBlocks: any;
  /** Validate GraphQL Input Translatable String Field */
  InputTranslatableString: any;
  InputVideoField: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Translate Fields */
  TranslatableField: any;
  UUID: any;
  /** Supported ContentType String for VIDEO */
  VIDEOContentType: any;
};



export type Category = {
  __typename?: 'Category';
  uuid: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['TranslatableField'];
  logo?: Maybe<Image>;
};

export type CategoryList = {
  __typename?: 'CategoryList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<Category>>;
};

export type Championship = {
  __typename?: 'Championship';
  uuid: Scalars['ID'];
  name: Scalars['TranslatableField'];
  shortName: Scalars['TranslatableField'];
  logo?: Maybe<Image>;
  cover?: Maybe<Image>;
  live?: Maybe<Scalars['Boolean']>;
  reach?: Maybe<ChampionshipReach>;
  managementEntity: ManagementEntity;
  championshipTeams?: Maybe<Array<Maybe<ChampionshipTeam>>>;
};

export type ChampionshipCompetition = {
  __typename?: 'ChampionshipCompetition';
  uuid: Scalars['ID'];
  name: Scalars['TranslatableField'];
  championship: Championship;
  championshipSport: ChampionshipSport;
  championshipStages?: Maybe<Array<Maybe<ChampionshipStage>>>;
  logo?: Maybe<Scalars['String']>;
};

export type ChampionshipCompetitionsList = {
  __typename?: 'ChampionshipCompetitionsList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<ChampionshipCompetition>>;
};

export type ChampionshipEntity = {
  __typename?: 'ChampionshipEntity';
  uuid: Scalars['ID'];
  championshipScore?: Maybe<Scalars['Float']>;
  groupScore?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  championshipTeam: ChampionshipTeam;
  championshipGroup: ChampionshipGroup;
  championshipMatchEntities?: Maybe<Array<Maybe<ChampionshipMatchEntity>>>;
  championshipEntityPlayers?: Maybe<Array<Maybe<ChampionshipEntityPlayer>>>;
};

export type ChampionshipEntityPlayer = {
  __typename?: 'ChampionshipEntityPlayer';
  uuid: Scalars['ID'];
  championshipEntity: ChampionshipEntity;
  player: Player;
  championshipMatchEntityPlayers?: Maybe<Array<Maybe<ChampionshipMatchEntityPlayer>>>;
};

export type ChampionshipEntityPlayerList = {
  __typename?: 'ChampionshipEntityPlayerList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<ChampionshipEntityPlayer>>;
};

export type ChampionshipEvent = {
  __typename?: 'ChampionshipEvent';
  key: Scalars['ID'];
  valueUnit?: Maybe<ChampionshipScoreUnit>;
  name?: Maybe<Scalars['TranslatableField']>;
  logo?: Maybe<Image>;
  configs: Scalars['JSON'];
  championshipSport?: Maybe<ChampionshipSport>;
};

export type ChampionshipEventsList = {
  __typename?: 'ChampionshipEventsList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<ChampionshipEvent>>;
};

export type ChampionshipGroup = {
  __typename?: 'ChampionshipGroup';
  uuid: Scalars['ID'];
  name: Scalars['TranslatableField'];
  championshipStage: ChampionshipStage;
  championshipMatches?: Maybe<Array<Maybe<ChampionshipMatch>>>;
  championshipEntities?: Maybe<Array<Maybe<ChampionshipEntity>>>;
};


export type ChampionshipGroupChampionshipEntitiesArgs = {
  orderBy?: Maybe<Array<Maybe<InputOrderChampionshipEntity>>>;
};

export type ChampionshipGroupsList = {
  __typename?: 'ChampionshipGroupsList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<ChampionshipGroup>>;
};

export type ChampionshipMatch = {
  __typename?: 'ChampionshipMatch';
  uuid: Scalars['ID'];
  cursor: Scalars['String'];
  datetime?: Maybe<Scalars['DateTime']>;
  location: Scalars['TranslatableField'];
  live?: Maybe<Scalars['Boolean']>;
  clock?: Maybe<Array<Maybe<ChampionshipMatchesClock>>>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  championshipGroup: ChampionshipGroup;
  championshipMatchEntities?: Maybe<Array<Maybe<ChampionshipMatchEntity>>>;
  championshipMatchEvents?: Maybe<Array<Maybe<ChampionshipMatchEvent>>>;
};


export type ChampionshipMatchChampionshipMatchEntitiesArgs = {
  orderBy?: Maybe<Array<Maybe<InputOrderChampionshipMatchEntity>>>;
};

export type ChampionshipMatchEntity = {
  __typename?: 'ChampionshipMatchEntity';
  uuid: Scalars['ID'];
  matchScore?: Maybe<Scalars['Float']>;
  drawScore?: Maybe<Scalars['Float']>;
  clockScore?: Maybe<Array<Maybe<Scalars['Float']>>>;
  position?: Maybe<Scalars['Int']>;
  championshipMatch: ChampionshipMatch;
  championshipEntity: ChampionshipEntity;
  championshipMatchEntityPlayers?: Maybe<Array<Maybe<ChampionshipMatchEntityPlayer>>>;
};


export type ChampionshipMatchEntityChampionshipMatchEntityPlayersArgs = {
  orderBy?: Maybe<InputOrderChampionshipMatchEntityPlayer>;
};

export type ChampionshipMatchEntityPlayer = {
  __typename?: 'ChampionshipMatchEntityPlayer';
  uuid: Scalars['ID'];
  type: ChampionshipMatchEntityPlayerType;
  number?: Maybe<Scalars['String']>;
  championshipEntityPlayer: ChampionshipEntityPlayer;
  championshipMatchEvents?: Maybe<Array<Maybe<ChampionshipMatchEvent>>>;
};

export enum ChampionshipMatchEntityPlayerType {
  Player = 'PLAYER',
  Staff = 'STAFF',
  PlayerSubstitute = 'PLAYER_SUBSTITUTE'
}

export enum ChampionshipMatchEntityType {
  Team = 'TEAM',
  Player = 'PLAYER'
}

export type ChampionshipMatchEvent = {
  __typename?: 'ChampionshipMatchEvent';
  uuid: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['Float']>;
  valueClockAggregated?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['TranslatableField']>;
  subtitle?: Maybe<Scalars['TranslatableField']>;
  time?: Maybe<Scalars['Float']>;
  championshipMatch: ChampionshipMatch;
  championshipMatchEntity?: Maybe<ChampionshipMatchEntity>;
  championshipMatchEntityPlayer?: Maybe<ChampionshipMatchEntityPlayer>;
  auxChampionshipMatchEntityPlayer?: Maybe<ChampionshipMatchEntityPlayer>;
  championshipEvent?: Maybe<ChampionshipEvent>;
};

export type ChampionshipMatchesClock = {
  __typename?: 'ChampionshipMatchesClock';
  title: Scalars['TranslatableField'];
  status: ClockStatus;
  direction: ClockDirection;
  lastValue: Scalars['Int'];
  lastTimestamp: Scalars['DateTime'];
  detail: ClockDetail;
  startedAt?: Maybe<Scalars['DateTime']>;
  stoppedAt?: Maybe<Scalars['DateTime']>;
  championshipMatchEntities?: Maybe<Array<Maybe<ChampionshipMatchEntity>>>;
};

export enum ChampionshipReach {
  Worldwide = 'WORLDWIDE',
  European = 'EUROPEAN',
  Asian = 'ASIAN',
  African = 'AFRICAN',
  American = 'AMERICAN',
  Oceanic = 'OCEANIC',
  Regional = 'REGIONAL',
  Local = 'LOCAL',
  National = 'NATIONAL'
}

export enum ChampionshipScoreDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ChampionshipScoreUnit {
  Meters = 'METERS',
  Centimeters = 'CENTIMETERS',
  Hours = 'HOURS',
  Minutes = 'MINUTES',
  Seconds = 'SECONDS',
  Milliseconds = 'MILLISECONDS',
  Goals = 'GOALS',
  Points = 'POINTS',
  Sets = 'SETS'
}

export type ChampionshipSport = {
  __typename?: 'ChampionshipSport';
  uuid: Scalars['ID'];
  name: Scalars['TranslatableField'];
  matchEntity: ChampionshipMatchEntityType;
  scoreDirection: ChampionshipScoreDirection;
  scoreUnit: ChampionshipScoreUnit;
  showScoreUnit?: Maybe<ChampionshipScoreUnit>;
  matchScoreChampionshipEventKey: Scalars['String'];
  drawScoreChampionshipEventKey?: Maybe<Scalars['String']>;
  matchDefaultChampionshipEventKey?: Maybe<Scalars['String']>;
  logo?: Maybe<Image>;
  configMatchEntityType: ConfigMatchEntityType;
  configMatchEntityMaxNumber?: Maybe<Scalars['Int']>;
};

export type ChampionshipSportsList = {
  __typename?: 'ChampionshipSportsList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<ChampionshipSport>>;
};

export type ChampionshipStage = {
  __typename?: 'ChampionshipStage';
  uuid: Scalars['ID'];
  cursor: Scalars['String'];
  name: Scalars['TranslatableField'];
  configGroupPoints?: Maybe<ConfigGroupPoints>;
  tagName: Scalars['TranslatableField'];
  championshipCompetition: ChampionshipCompetition;
  previousStage?: Maybe<ChampionshipStage>;
  nextStages?: Maybe<Array<Maybe<ChampionshipStage>>>;
  championshipGroups?: Maybe<Array<Maybe<ChampionshipGroup>>>;
};

export type ChampionshipStagesList = {
  __typename?: 'ChampionshipStagesList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<ChampionshipStage>>;
};

export type ChampionshipTeam = {
  __typename?: 'ChampionshipTeam';
  uuid: Scalars['ID'];
  team: Team;
  championship: Championship;
};

export type ChampionshipsList = {
  __typename?: 'ChampionshipsList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<Championship>>;
};

export enum ClockDetail {
  Milliseconds = 'MILLISECONDS',
  Seconds = 'SECONDS',
  Minutes = 'MINUTES'
}

export enum ClockDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ClockStatus {
  Play = 'PLAY',
  Pause = 'PAUSE',
  Stop = 'STOP'
}

export type ConfigGroupPoints = {
  __typename?: 'ConfigGroupPoints';
  id: ConfigGroupPointsKeys;
  type: ConfigGroupPointsTypes;
  pairConfig?: Maybe<Scalars['JSON']>;
};

export enum ConfigGroupPointsKeys {
  ConfigGroupPointsType_1 = 'CONFIG_GROUP_POINTS_TYPE_1',
  ConfigGroupPointsType_2 = 'CONFIG_GROUP_POINTS_TYPE_2'
}

export enum ConfigGroupPointsTypes {
  Pair = 'PAIR',
  Grid = 'GRID'
}

export enum ConfigMatchEntityType {
  Pair = 'PAIR',
  Grid = 'GRID'
}


export type FeedPost = {
  __typename?: 'FeedPost';
  uuid: Scalars['ID'];
  cursor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['TranslatableField'];
  subtitle: Scalars['TranslatableField'];
  note?: Maybe<Scalars['TranslatableField']>;
  blocks: Scalars['TranslatableField'];
  type: FeedPostType;
  origin: FeedPostOrigin;
  cover?: Maybe<Image>;
  highlight: FeedPostHightlight;
  published: FeedPostPublished;
  cta?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  author?: Maybe<User>;
  advertisement?: Maybe<FeedPostAdvertisement>;
};

export type FeedPostAdvertisement = {
  __typename?: 'FeedPostAdvertisement';
  page: FeedPostPage;
  position: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
};

export type FeedPostHightlight = {
  __typename?: 'FeedPostHightlight';
  highlighted: Scalars['Boolean'];
  position: Scalars['Int'];
  stretch: Scalars['Boolean'];
  video?: Maybe<Video>;
  image?: Maybe<Image>;
};

export type FeedPostList = {
  __typename?: 'FeedPostList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<FeedPost>>;
};

export enum FeedPostOrderKeys {
  CreatedAt = 'createdAt',
  PublishedAt = 'publishedAt'
}

export enum FeedPostOrigin {
  Internal = 'INTERNAL',
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Pub = 'PUB'
}

export enum FeedPostPage {
  News = 'NEWS',
  Highlights = 'HIGHLIGHTS',
  Social = 'SOCIAL'
}

export type FeedPostPublished = {
  __typename?: 'FeedPostPublished';
  app: Scalars['Boolean'];
  site: Scalars['Boolean'];
};

export enum FeedPostPublishedType {
  App = 'APP',
  Site = 'SITE'
}

export enum FeedPostType {
  Gallery = 'GALLERY',
  News = 'NEWS',
  Opinion = 'OPINION',
  Video = 'VIDEO',
  Status = 'STATUS'
}

export enum GenericOrderKeys {
  CreatedAt = 'createdAt'
}


export type Image = {
  __typename?: 'Image';
  url: Scalars['String'];
  contentType: Scalars['IMAGEContentType'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type InputAddChampionshipEntityPlayerToChampionshipMatchEntity = {
  uuid: Scalars['ID'];
};

export type InputCategory = {
  name: Scalars['InputTranslatableString'];
  logo?: Maybe<Scalars['InputImageField']>;
};

export type InputChampionship = {
  name: Scalars['InputTranslatableString'];
  shortName: Scalars['InputTranslatableString'];
  reach: ChampionshipReach;
  logo?: Maybe<Scalars['InputImageField']>;
  cover?: Maybe<Scalars['InputImageField']>;
  managementEntity?: Maybe<Scalars['UUID']>;
};

export type InputChampionshipCompetition = {
  championship: Scalars['UUID'];
  championshipSport: Scalars['UUID'];
  name: Scalars['InputTranslatableString'];
};

export type InputChampionshipCompetitionsFilter = {
  championshipUuid: Scalars['UUID'];
};

export type InputChampionshipEntityGroupScore = {
  uuid: Scalars['UUID'];
  groupScore?: Maybe<Scalars['Float']>;
};

export type InputChampionshipEntityWeight = {
  uuid: Scalars['UUID'];
  weight?: Maybe<Scalars['Float']>;
};

export type InputChampionshipEventsFilter = {
  championshipSportUuid: Scalars['UUID'];
};

export type InputChampionshipFilter = {
  championshipSports?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

export type InputChampionshipGroup = {
  championshipStage: Scalars['UUID'];
  name: Scalars['InputTranslatableString'];
};

export type InputChampionshipGroupsFilter = {
  championshipStageUuid: Scalars['UUID'];
};

export type InputChampionshipMatchEntities = {
  uuid: Scalars['UUID'];
  matchScore?: Maybe<Scalars['Float']>;
  drawScore?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Int']>;
};

export type InputChampionshipMatchEntityPlayer = {
  uuid: Scalars['ID'];
  type?: Maybe<ChampionshipMatchEntityPlayerType>;
  number?: Maybe<Scalars['String']>;
};

export type InputChampionshipMatchesClock = {
  title: Scalars['InputTranslatableString'];
  direction?: Maybe<ClockDirection>;
  startValue?: Maybe<Scalars['DateTime']>;
};

export type InputChampionshipMatchesFilter = {
  before?: Maybe<Scalars['DateTime']>;
  after?: Maybe<Scalars['DateTime']>;
  sports?: Maybe<Array<Maybe<Scalars['String']>>>;
  championships?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InputChampionshipStage = {
  championshipCompetition: Scalars['UUID'];
  name: Scalars['InputTranslatableString'];
  setConfigGroupPoints?: Maybe<ConfigGroupPointsKeys>;
};

export type InputChampionshipStagesFilter = {
  championshipCompetitionUuid: Scalars['UUID'];
};

export type InputCreateChampionshipMatch = {
  datetime?: Maybe<Scalars['DateTime']>;
  location: Scalars['InputTranslatableString'];
  championshipGroup: Scalars['UUID'];
};

export type InputCreatePageCategory = {
  title: Scalars['InputTranslatableString'];
  externalUrl?: Maybe<Scalars['String']>;
  parentPageCategory?: Maybe<Scalars['UUID']>;
  page?: Maybe<Scalars['UUID']>;
};

export type InputCreatedAtFilter = {
  after?: Maybe<Scalars['DateTime']>;
  before?: Maybe<Scalars['DateTime']>;
};

export type InputEventData = {
  championshipEventKey: Scalars['String'];
  championshipMatchEntityUuid?: Maybe<Scalars['UUID']>;
  championshipMatchEntityPlayerUuid?: Maybe<Scalars['UUID']>;
  auxChampionshipMatchEntityPlayerUuid?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['InputTranslatableString']>;
  subtitle?: Maybe<Scalars['InputTranslatableString']>;
  value?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['Float']>;
};

export type InputFeedPostAdvertisement = {
  page: FeedPostPage;
  position: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type InputFeedPostHighlight = {
  highlighted: Scalars['Boolean'];
  position: Scalars['Int'];
  stretch: Scalars['Boolean'];
  video?: Maybe<Scalars['InputVideoField']>;
  image?: Maybe<Scalars['InputImageField']>;
};

export type InputFeedPostOrder = {
  key?: Maybe<FeedPostOrderKeys>;
  direction?: Maybe<OrderDirection>;
};

export type InputFeedPostPublished = {
  app: Scalars['Boolean'];
  site: Scalars['Boolean'];
};

export type InputFeedPosts = {
  title: Scalars['InputTranslatableString'];
  subtitle: Scalars['InputTranslatableString'];
  note?: Maybe<Scalars['InputTranslatableString']>;
  blocks: Scalars['InputTranslatableBlocks'];
  type: FeedPostType;
  origin: FeedPostOrigin;
  cover?: Maybe<Scalars['InputImageField']>;
  highlight: InputFeedPostHighlight;
  published: InputFeedPostPublished;
  cta?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  category?: Maybe<Scalars['UUID']>;
  author?: Maybe<Scalars['UUID']>;
  advertisement?: Maybe<InputFeedPostAdvertisement>;
};

export type InputFeedPostsFilter = {
  deleted?: Maybe<Scalars['Boolean']>;
  query?: Maybe<Scalars['String']>;
  origin?: Maybe<Array<Maybe<FeedPostOrigin>>>;
  type?: Maybe<Array<Maybe<FeedPostType>>>;
  createdAt?: Maybe<InputCreatedAtFilter>;
  highlighted?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Array<Maybe<FeedPostPublishedType>>>;
};

export type InputGenericFilter = {
  deleted?: Maybe<Scalars['Boolean']>;
  query?: Maybe<Scalars['String']>;
  createdAt?: Maybe<InputCreatedAtFilter>;
};

export type InputGenericOrder = {
  key?: Maybe<GenericOrderKeys>;
  direction?: Maybe<OrderDirection>;
};


export type InputOrderChampionshipEntity = {
  weight?: Maybe<OrderBy>;
  groupScore?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

export type InputOrderChampionshipEntityPlayer = {
  player?: Maybe<InputOrderPlayer>;
};

export type InputOrderChampionshipMatchEntity = {
  position?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

export type InputOrderChampionshipMatchEntityPlayer = {
  championshipEntityPlayer?: Maybe<InputOrderChampionshipEntityPlayer>;
};

export type InputOrderPlayer = {
  firstName?: Maybe<OrderBy>;
};

export type InputPage = {
  slug: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['InputTranslatableString'];
  subtitle?: Maybe<Scalars['InputTranslatableString']>;
  blocks: Scalars['InputTranslatablePageBlocks'];
  cover?: Maybe<Scalars['InputImageField']>;
  note?: Maybe<Scalars['String']>;
};

export type InputPageCategoriesFilter = {
  parentUuid?: Maybe<Scalars['UUID']>;
};

export type InputPagePagination = {
  page?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type InputPartialFeedPostPublished = {
  app?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Scalars['Boolean']>;
};

export type InputPlayer = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  identification: Scalars['String'];
  teams?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  championshipSports?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

export type InputPlayersFilter = {
  query?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  championshipSports?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

export type InputQueryFilter = {
  query?: Maybe<Scalars['String']>;
};

export type InputTag = {
  name: Scalars['InputTranslatableString'];
};

export type InputTeam = {
  name: Scalars['String'];
  shortName: Scalars['String'];
  colors: Array<Maybe<Scalars['String']>>;
  logo?: Maybe<Scalars['InputImageField']>;
};




export type InputUpdateChampionshipMatch = {
  datetime?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Scalars['InputTranslatableString']>;
};

export type InputUpdatePageCategory = {
  title?: Maybe<Scalars['InputTranslatableString']>;
  externalUrl?: Maybe<Scalars['String']>;
  parentPageCategory?: Maybe<Scalars['UUID']>;
  page?: Maybe<Scalars['UUID']>;
};



export type ManagementEntitiesList = {
  __typename?: 'ManagementEntitiesList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<ManagementEntity>>;
};

export type ManagementEntity = {
  __typename?: 'ManagementEntity';
  uuid: Scalars['ID'];
  name: Scalars['TranslatableField'];
  championships?: Maybe<Array<Maybe<Championship>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createFeedPost: FeedPost;
  updateFeedPost: FeedPost;
  deleteFeedPost: FeedPost;
  highlightFeedPost: FeedPost;
  stretchHighlightFeedPost: FeedPost;
  publishFeedPost: FeedPost;
  orderFeedPostHighlights: Array<Maybe<FeedPost>>;
  createCategory: Category;
  updateCategory: Category;
  deleteCategory: Category;
  createTag: Tag;
  createTags: Array<Maybe<Tag>>;
  createTeam: Team;
  updateTeam: Team;
  createPlayer: Player;
  updatePlayer: Player;
  addTeamToPlayer: Player;
  addChampionshipSportToPlayer: Player;
  deleteTeamFromPlayer: Player;
  deleteChampionshipSportFromPlayer: Player;
  createChampionship: Championship;
  updateChampionship: Championship;
  createChampionshipTeams: Array<Maybe<ChampionshipTeam>>;
  createChampionshipCompetition: ChampionshipCompetition;
  updateChampionshipCompetition: ChampionshipCompetition;
  deleteChampionshipCompetition: ChampionshipCompetition;
  createChampionshipStage: ChampionshipStage;
  updateChampionshipStage: ChampionshipStage;
  deleteChampionshipStage: ChampionshipStage;
  createChampionshipGroup: ChampionshipGroup;
  updateChampionshipGroup: ChampionshipGroup;
  deleteChampionshipGroup: ChampionshipGroup;
  createChampionshipEntities: Array<Maybe<ChampionshipEntity>>;
  deleteChampionshipEntities: Array<Maybe<ChampionshipEntity>>;
  championshipEntityUpdateWeight: Array<Maybe<ChampionshipEntity>>;
  championshipEntityUpdateGroupScore: Array<Maybe<ChampionshipEntity>>;
  createChampionshipMatch: ChampionshipMatch;
  updateChampionshipMatch: ChampionshipMatch;
  deleteChampionshipMatch: ChampionshipMatch;
  createChampionshipMatchEntities: Array<Maybe<ChampionshipMatchEntity>>;
  updateChampionshipMatchEntities: Array<Maybe<ChampionshipMatchEntity>>;
  deleteChampionshipMatchEntity: ChampionshipMatchEntity;
  championshipMatchClockCreate: ChampionshipMatch;
  championshipMatchClockUpdateStatus: ChampionshipMatch;
  championshipMatchEnd: ChampionshipMatch;
  championshipMatchClockDelete: ChampionshipMatch;
  championshipMatchEventCreate: ChampionshipMatch;
  championshipMatchEventDelete: ChampionshipMatch;
  addTeamsToChampionshipMatch: ChampionshipMatch;
  updateChampionshipMatchEntityPlayer: Array<Maybe<ChampionshipMatchEntityPlayer>>;
  addChampionshipMatchEntityPlayer: Array<Maybe<ChampionshipMatchEntityPlayer>>;
  deleteChampionshipMatchEntityPlayer?: Maybe<ChampionshipMatchEntityPlayer>;
  createChampionshipEntityPlayers: Array<Maybe<ChampionshipEntityPlayer>>;
  createPageCategory: PageCategory;
  updatePageCategory: PageCategory;
  deletePageCategory: PageCategory;
  createPage: Page;
  updatePage: Page;
  deletePage: Page;
};


export type MutationCreateFeedPostArgs = {
  input: InputFeedPosts;
};


export type MutationUpdateFeedPostArgs = {
  uuid: Scalars['UUID'];
  input: InputFeedPosts;
};


export type MutationDeleteFeedPostArgs = {
  uuid: Scalars['UUID'];
};


export type MutationHighlightFeedPostArgs = {
  uuid: Scalars['UUID'];
  highlighted: Scalars['Boolean'];
};


export type MutationStretchHighlightFeedPostArgs = {
  uuid: Scalars['UUID'];
  stretch: Scalars['Boolean'];
};


export type MutationPublishFeedPostArgs = {
  uuid: Scalars['UUID'];
  published: InputPartialFeedPostPublished;
};


export type MutationOrderFeedPostHighlightsArgs = {
  order: Array<Maybe<OrderFeedPostsHighlightsArgs>>;
};


export type MutationCreateCategoryArgs = {
  input: InputCategory;
};


export type MutationUpdateCategoryArgs = {
  uuid: Scalars['UUID'];
  input: InputCategory;
};


export type MutationDeleteCategoryArgs = {
  uuid: Scalars['UUID'];
};


export type MutationCreateTagArgs = {
  input: InputTag;
};


export type MutationCreateTagsArgs = {
  input: Array<Maybe<InputTag>>;
};


export type MutationCreateTeamArgs = {
  input: InputTeam;
};


export type MutationUpdateTeamArgs = {
  uuid: Scalars['UUID'];
  input: InputTeam;
};


export type MutationCreatePlayerArgs = {
  input: InputPlayer;
};


export type MutationUpdatePlayerArgs = {
  uuid: Scalars['UUID'];
  input: InputPlayer;
};


export type MutationAddTeamToPlayerArgs = {
  playerUuid: Scalars['UUID'];
  teamUuid: Scalars['UUID'];
};


export type MutationAddChampionshipSportToPlayerArgs = {
  playerUuid: Scalars['UUID'];
  championshipSportUuid: Scalars['UUID'];
};


export type MutationDeleteTeamFromPlayerArgs = {
  playerUuid: Scalars['UUID'];
  teamUuid: Scalars['UUID'];
};


export type MutationDeleteChampionshipSportFromPlayerArgs = {
  playerUuid: Scalars['UUID'];
  championshipSportUuid: Scalars['UUID'];
};


export type MutationCreateChampionshipArgs = {
  input: InputChampionship;
};


export type MutationUpdateChampionshipArgs = {
  uuid: Scalars['UUID'];
  input: InputChampionship;
};


export type MutationCreateChampionshipTeamsArgs = {
  championshipUuid: Scalars['UUID'];
  teamUuids: Array<Maybe<Scalars['UUID']>>;
};


export type MutationCreateChampionshipCompetitionArgs = {
  input: InputChampionshipCompetition;
};


export type MutationUpdateChampionshipCompetitionArgs = {
  uuid: Scalars['UUID'];
  input: InputChampionshipCompetition;
};


export type MutationDeleteChampionshipCompetitionArgs = {
  uuid: Scalars['UUID'];
};


export type MutationCreateChampionshipStageArgs = {
  input: InputChampionshipStage;
};


export type MutationUpdateChampionshipStageArgs = {
  uuid: Scalars['UUID'];
  input: InputChampionshipStage;
};


export type MutationDeleteChampionshipStageArgs = {
  uuid: Scalars['UUID'];
};


export type MutationCreateChampionshipGroupArgs = {
  input: InputChampionshipGroup;
};


export type MutationUpdateChampionshipGroupArgs = {
  uuid: Scalars['UUID'];
  input: InputChampionshipGroup;
};


export type MutationDeleteChampionshipGroupArgs = {
  uuid: Scalars['UUID'];
};


export type MutationCreateChampionshipEntitiesArgs = {
  championshipGroupUuid: Scalars['UUID'];
  championshipTeamUuids: Array<Maybe<Scalars['UUID']>>;
};


export type MutationDeleteChampionshipEntitiesArgs = {
  uuids: Array<Maybe<Scalars['UUID']>>;
};


export type MutationChampionshipEntityUpdateWeightArgs = {
  championshipEntityWeight: Array<Maybe<InputChampionshipEntityWeight>>;
};


export type MutationChampionshipEntityUpdateGroupScoreArgs = {
  championshipEntityGroupScore: Array<Maybe<InputChampionshipEntityGroupScore>>;
};


export type MutationCreateChampionshipMatchArgs = {
  input: InputCreateChampionshipMatch;
};


export type MutationUpdateChampionshipMatchArgs = {
  uuid: Scalars['UUID'];
  input: InputUpdateChampionshipMatch;
};


export type MutationDeleteChampionshipMatchArgs = {
  uuid: Scalars['UUID'];
};


export type MutationCreateChampionshipMatchEntitiesArgs = {
  championshipMatchUuid: Scalars['UUID'];
  championshipEntityUuids: Array<Maybe<Scalars['UUID']>>;
};


export type MutationUpdateChampionshipMatchEntitiesArgs = {
  championshipMatchUuid: Scalars['UUID'];
  championshipMatchEntities: Array<Maybe<InputChampionshipMatchEntities>>;
};


export type MutationDeleteChampionshipMatchEntityArgs = {
  uuid: Scalars['UUID'];
};


export type MutationChampionshipMatchClockCreateArgs = {
  uuid: Scalars['UUID'];
  clock: InputChampionshipMatchesClock;
};


export type MutationChampionshipMatchClockUpdateStatusArgs = {
  uuid: Scalars['UUID'];
  clockStatus: ClockStatus;
};


export type MutationChampionshipMatchEndArgs = {
  uuid: Scalars['UUID'];
};


export type MutationChampionshipMatchClockDeleteArgs = {
  uuid: Scalars['UUID'];
  clockIndex: Scalars['Int'];
};


export type MutationChampionshipMatchEventCreateArgs = {
  uuid: Scalars['UUID'];
  eventData: InputEventData;
};


export type MutationChampionshipMatchEventDeleteArgs = {
  championshipMatchEventUuid: Scalars['UUID'];
};


export type MutationAddTeamsToChampionshipMatchArgs = {
  uuid: Scalars['UUID'];
  teamUuids: Array<Maybe<Scalars['UUID']>>;
};


export type MutationUpdateChampionshipMatchEntityPlayerArgs = {
  championshipMatchEntityPlayers: Array<Maybe<InputChampionshipMatchEntityPlayer>>;
};


export type MutationAddChampionshipMatchEntityPlayerArgs = {
  championshipMatchEntityUuid: Scalars['UUID'];
  championshipEntityPlayers: Array<Maybe<InputAddChampionshipEntityPlayerToChampionshipMatchEntity>>;
};


export type MutationDeleteChampionshipMatchEntityPlayerArgs = {
  uuid: Scalars['UUID'];
};


export type MutationCreateChampionshipEntityPlayersArgs = {
  championshipEntityUuid: Scalars['UUID'];
  playerUuids: Array<Maybe<Scalars['UUID']>>;
};


export type MutationCreatePageCategoryArgs = {
  input: InputCreatePageCategory;
};


export type MutationUpdatePageCategoryArgs = {
  uuid: Scalars['UUID'];
  input: InputUpdatePageCategory;
};


export type MutationDeletePageCategoryArgs = {
  uuid: Scalars['UUID'];
};


export type MutationCreatePageArgs = {
  input: InputPage;
};


export type MutationUpdatePageArgs = {
  uuid: Scalars['UUID'];
  input: InputPage;
};


export type MutationDeletePageArgs = {
  uuid: Scalars['UUID'];
};

export enum NonSocialFeedPostOrigin {
  Internal = 'INTERNAL',
  Pub = 'PUB'
}

export enum OrderBy {
  Asc = 'asc',
  Desc = 'desc'
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderFeedPostsHighlightsArgs = {
  uuid: Scalars['UUID'];
  position: Scalars['Int'];
};

export type Page = {
  __typename?: 'Page';
  uuid: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['TranslatableField'];
  subtitle?: Maybe<Scalars['TranslatableField']>;
  blocks: Scalars['TranslatableField'];
  cover?: Maybe<Image>;
  note?: Maybe<Scalars['String']>;
  pageCategories?: Maybe<Array<Maybe<PageCategory>>>;
};

export type PageCategory = {
  __typename?: 'PageCategory';
  uuid: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['TranslatableField'];
  externalUrl?: Maybe<Scalars['String']>;
  pageCategories?: Maybe<Array<Maybe<PageCategory>>>;
  parentPageCategory?: Maybe<PageCategory>;
  page?: Maybe<Page>;
};

export type PageCategoryList = {
  __typename?: 'PageCategoryList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<PageCategory>>;
};

export type PageList = {
  __typename?: 'PageList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<Page>>;
};

export type Pagination = {
  __typename?: 'Pagination';
  pageEdgesCount?: Maybe<Scalars['Int']>;
  pageEdgesMaxCount?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
};

export type Player = {
  __typename?: 'Player';
  uuid: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  identification: Scalars['String'];
  teams?: Maybe<Array<Maybe<Team>>>;
  championshipSports?: Maybe<Array<Maybe<ChampionshipSport>>>;
};

export type PlayerList = {
  __typename?: 'PlayerList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<Player>>;
};

export type Profile = {
  __typename?: 'Profile';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  self?: Maybe<User>;
  authors?: Maybe<Array<Maybe<User>>>;
  feedPosts: FeedPostList;
  feedPost?: Maybe<FeedPost>;
  categories: CategoryList;
  category?: Maybe<Category>;
  tags: TagList;
  tag?: Maybe<Tag>;
  managementEntities: ManagementEntitiesList;
  teams: TeamList;
  team: Team;
  player: Player;
  players: PlayerList;
  championship: Championship;
  championships: ChampionshipsList;
  championshipSports: ChampionshipSportsList;
  championshipCompetitions: ChampionshipCompetitionsList;
  championshipCompetition: ChampionshipCompetition;
  championshipStages: ChampionshipStagesList;
  championshipStage: ChampionshipStage;
  championshipStagesConfigGroupPoints: Array<Maybe<ConfigGroupPoints>>;
  championshipGroups: ChampionshipGroupsList;
  championshipGroup: ChampionshipGroup;
  championshipMatch: ChampionshipMatch;
  championshipEvents: ChampionshipEventsList;
  championshipEntityPlayers: ChampionshipEntityPlayerList;
  pageCategories: PageCategoryList;
  pageCategory: PageCategory;
  pages: PageList;
  page: Page;
};


export type QueryFeedPostsArgs = {
  filter?: Maybe<InputFeedPostsFilter>;
  order?: Maybe<InputFeedPostOrder>;
  pagination?: Maybe<InputPagePagination>;
};


export type QueryFeedPostArgs = {
  uuid: Scalars['UUID'];
};


export type QueryCategoriesArgs = {
  filter?: Maybe<InputGenericFilter>;
  order?: Maybe<InputGenericOrder>;
  pagination?: Maybe<InputPagePagination>;
};


export type QueryCategoryArgs = {
  uuid: Scalars['UUID'];
};


export type QueryTagsArgs = {
  filter?: Maybe<InputGenericFilter>;
  order?: Maybe<InputGenericOrder>;
  pagination?: Maybe<InputPagePagination>;
};


export type QueryTagArgs = {
  uuid: Scalars['UUID'];
};


export type QueryManagementEntitiesArgs = {
  pagination?: Maybe<InputPagePagination>;
};


export type QueryTeamsArgs = {
  filter?: Maybe<InputQueryFilter>;
  pagination?: Maybe<InputPagePagination>;
};


export type QueryTeamArgs = {
  uuid: Scalars['UUID'];
};


export type QueryPlayerArgs = {
  uuid: Scalars['UUID'];
};


export type QueryPlayersArgs = {
  filter?: Maybe<InputPlayersFilter>;
  pagination?: Maybe<InputPagePagination>;
};


export type QueryChampionshipArgs = {
  uuid?: Maybe<Scalars['UUID']>;
};


export type QueryChampionshipsArgs = {
  pagination?: Maybe<InputPagePagination>;
};


export type QueryChampionshipSportsArgs = {
  pagination?: Maybe<InputPagePagination>;
};


export type QueryChampionshipCompetitionsArgs = {
  filter: InputChampionshipCompetitionsFilter;
  pagination?: Maybe<InputPagePagination>;
};


export type QueryChampionshipCompetitionArgs = {
  uuid?: Maybe<Scalars['UUID']>;
};


export type QueryChampionshipStagesArgs = {
  filter: InputChampionshipStagesFilter;
  pagination?: Maybe<InputPagePagination>;
};


export type QueryChampionshipStageArgs = {
  uuid?: Maybe<Scalars['UUID']>;
};


export type QueryChampionshipGroupsArgs = {
  filter: InputChampionshipGroupsFilter;
  pagination?: Maybe<InputPagePagination>;
};


export type QueryChampionshipGroupArgs = {
  uuid?: Maybe<Scalars['UUID']>;
};


export type QueryChampionshipMatchArgs = {
  uuid?: Maybe<Scalars['UUID']>;
};


export type QueryChampionshipEventsArgs = {
  filter: InputChampionshipEventsFilter;
  pagination?: Maybe<InputPagePagination>;
};


export type QueryChampionshipEntityPlayersArgs = {
  championshipEntityUuid: Scalars['UUID'];
  pagination?: Maybe<InputPagePagination>;
};


export type QueryPageCategoriesArgs = {
  filter?: Maybe<InputPageCategoriesFilter>;
  pagination?: Maybe<InputPagePagination>;
};


export type QueryPageCategoryArgs = {
  uuid: Scalars['UUID'];
};


export type QueryPagesArgs = {
  pagination?: Maybe<InputPagePagination>;
};


export type QueryPageArgs = {
  uuid: Scalars['UUID'];
};

export type Tag = {
  __typename?: 'Tag';
  uuid: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['TranslatableField'];
};

export type TagList = {
  __typename?: 'TagList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<Tag>>;
};

export type Team = {
  __typename?: 'Team';
  uuid: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  shortName: Scalars['String'];
  colors: Array<Maybe<Scalars['String']>>;
  logo?: Maybe<Image>;
  players?: Maybe<Array<Maybe<Player>>>;
};

export type TeamList = {
  __typename?: 'TeamList';
  pagination?: Maybe<Pagination>;
  edges: Array<Maybe<Team>>;
};


export type TranslatableJsonArrayInput = {
  pt?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  en?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};


export type User = {
  __typename?: 'User';
  uuid: Scalars['ID'];
  roles?: Maybe<Scalars['JSON']>;
  profile?: Maybe<Profile>;
};


export type Video = {
  __typename?: 'Video';
  url: Scalars['String'];
  contentType: Scalars['VIDEOContentType'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};
