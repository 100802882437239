import { ROUTES } from 'routes/config';

import { IconPress } from '../assets/icons/iconPress';
import { MenuProps } from '../typings/menu';

import { IconAdvertisement } from 'assets/icons/iconAdvertisement';
import { IconCategories } from 'assets/icons/iconCategories';
import { IconFeed } from 'assets/icons/iconFeed';
import { IconPages } from 'assets/icons/iconPages';
import { IconPlusCircle } from 'assets/icons/iconPlusCircle';
import { IconSocial } from 'assets/icons/iconSocial';

const I18N_PREFIX = 'mainMenu';

/**
 * MAIN MENU SECTION
 */
export const mainMenu: MenuProps[] = [
  {
    key: ROUTES.Press,
    path: ROUTES.Press,
    title: `${I18N_PREFIX}.content.default`,
    icon: '',
    submenu: [
      {
        key: ROUTES.PressFeed,
        path: ROUTES.PressFeed,
        title: `${I18N_PREFIX}.content.press`,
        icon: IconPress,
        submenu: [],
      },
      {
        key: '/social/feed',
        path: '/social/feed',
        title: `${I18N_PREFIX}.content.social`,
        icon: IconSocial,
        submenu: [],
      },
    ],
  },
  {
    key: ROUTES.Sports,
    path: ROUTES.Sports,
    title: `${I18N_PREFIX}.sports.default`,
    icon: '',
    submenu: [
      {
        key: ROUTES.SportsChampionships,
        path: ROUTES.SportsChampionships,
        title: `${I18N_PREFIX}.sports.championships`,
        icon: IconPlusCircle,
        submenu: [],
      },
      {
        key: ROUTES.SportsTeams,
        path: ROUTES.SportsTeams,
        title: `${I18N_PREFIX}.sports.teams`,
        icon: IconPlusCircle,
        submenu: [],
      },
      {
        key: ROUTES.SportsPlayers,
        path: ROUTES.SportsPlayers,
        title: `${I18N_PREFIX}.sports.players`,
        icon: IconPlusCircle,
        submenu: [],
      },
    ],
  },
  {
    key: ROUTES.Highlights,
    path: ROUTES.Highlights,
    title: `${I18N_PREFIX}.manageApp.default`,
    icon: '',
    submenu: [
      {
        key: ROUTES.Highlights,
        path: ROUTES.Highlights,
        title: `${I18N_PREFIX}.manageApp.feed`,
        icon: IconFeed,
        submenu: [],
      },
      {
        key: ROUTES.Advertisement,
        path: ROUTES.Advertisement,
        title: `${I18N_PREFIX}.manageApp.advertisement`,
        icon: IconAdvertisement,
        submenu: [],
      },
    ],
  },
  {
    key: ROUTES.Website,
    path: ROUTES.Website,
    title: `${I18N_PREFIX}.website.default`,
    icon: '',
    submenu: [
      {
        key: ROUTES.WebsiteCategories,
        path: ROUTES.WebsiteCategories,
        title: `${I18N_PREFIX}.website.categories`,
        icon: IconCategories,
        submenu: [],
      },
      {
        key: ROUTES.WebsitePages,
        path: ROUTES.WebsitePages,
        title: `${I18N_PREFIX}.website.pages`,
        icon: IconPages,
        submenu: [],
      },
    ],
  },
];
