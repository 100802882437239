import { useMutation } from '@apollo/client';
import { Button, Modal, Table } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CreateChampionshipMatchEntities } from 'queries/sportsChampionshipMatchTeams';
import {
  ChampionshipEntity,
  ChampionshipTeam,
  Mutation,
  MutationCreateChampionshipMatchEntitiesArgs,
} from 'queries/types';

interface Props {
  isLoading: boolean;
  showModal: boolean;
  onClose: () => void;
  teams?: ChampionshipEntity[];
  refetch: any;
}

export const TeamsAddModal = ({ isLoading, showModal, onClose, teams, refetch }: Props) => {
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string }>();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [addTeamsToChampionshipMatch, { loading: isAdding, data: isAdded }] = useMutation<
    Mutation,
    MutationCreateChampionshipMatchEntitiesArgs
  >(CreateChampionshipMatchEntities, refetch);

  const handleOk = () => {
    addTeamsToChampionshipMatch({
      variables: {
        championshipMatchUuid: uuid,
        championshipEntityUuids: selectedRowKeys,
      },
    })
      .catch((error) => {
        console.error(error);
      })
      .then(onClose);
  };

  /**
   * Handles modal close button click
   * Closes the modal without any mutation happening
   */
  const handleCancel = useCallback(() => {
    setSelectedRowKeys([]);
    onClose();
  }, [onClose]);

  const tableColumns = [
    {
      title: '',
      dataIndex: 'championshipTeam',
      render: (championshipTeam: ChampionshipTeam) => <span>{championshipTeam.team.name}</span>,
    },
    {
      title: '',
    },
  ];

  const list = (teams as ChampionshipEntity[]) || [];

  return (
    <Modal
      width="960px"
      title={t('sportsMatch.sections.teams.addTeam')}
      visible={showModal}
      onCancel={handleCancel}
      centered={true}
      footer={[
        <Button key="reset" loading={isAdding} onClick={handleCancel}>
          {t('actions.cancel')}
        </Button>,
        <Button key="submit" type="primary" loading={isAdding} onClick={handleOk}>
          {t('actions.confirm')}
        </Button>,
      ]}
    >
      <Table
        columns={tableColumns}
        dataSource={list}
        //   loading={loading || mutationLoading}
        rowKey="uuid"
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          type: 'radio',
          preserveSelectedRowKeys: false,
          onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
          },
        }}
      />
    </Modal>
  );
};
