import {
  CategoryList,
  FeedPost,
  FeedPostOrigin,
  FeedPostType,
  InputFeedPosts,
  TagList,
} from 'queries/types';

export const transformFormValues = (
  section: FeedPostType,
  values: any,
  published?: boolean,
  newTags?: any,
  allTags?: any
): InputFeedPosts => {
  return {
    ...values,
    note: {
      pt: values.note || '',
    },
    title: {
      pt: values.title || '',
    },
    subtitle: {
      pt: values.subtitle || '',
    },
    category: values.category ? optionsToUuid(values.category) : null,
    type: values.type || section,
    origin: FeedPostOrigin.Internal,
    highlight: {
      highlighted: values.highlightValues ? values.highlightValues.highlighted : false,
      position: values.highlightValues ? values.highlightValues.position : 0,
      stretch: values.highlightValues ? values.highlightValues.stretch : true,
      image: values.highlight,
    },
    published: {
      app: values.publishedApp === undefined ? published : published && values.publishedApp,
      site: values.publishedSite === undefined ? published : published && values.publishedSite,
    },
    publishedApp: undefined,
    publishedSite: undefined,
    tags: generateTagsForSave(values.tags, newTags, allTags),
    blocks: transformBlocks(values.blocks || []),
    highlightValues: undefined,
    author: values.author ? values.author : null,
  };
};

export const categoriesToOptions = (
  categories: CategoryList | TagList
): [{ label: string; value: string }] => {
  const options: any = [];

  categories.edges.forEach((category: any) => {
    options.push({
      label: category?.name.pt,
      value: `${category?.name.pt} | ${category?.uuid}`,
    });
  });
  return options;
};

const optionsToUuid = (value: string | string[]): string | string[] => {
  const separator: string = ' | ';

  if (Array.isArray(value)) {
    return value.map((option) => getLastWord(option, separator));
  }

  return getLastWord(value, separator);
};

const getLastWord = (words: string, separator: string): string => {
  return (words && words.split(separator)?.pop()) || '';
};

interface BlocksProps {
  time: number;
  blocks: any;
  version: string;
}

const transformBlocks = (blocks: any): { pt: BlocksProps } => {
  return {
    pt: {
      time: new Date().getTime(),
      blocks,
      version: '1.9.0',
    },
  };
};

export const getCustomTags = (values: string | string[], tags?: TagList) => {
  const tagsUuids = optionsToUuid(values);
  const newTags = [];
  const existingTags = [];

  if (tags && tagsUuids) {
    for (let c = 0; c < tagsUuids.length; c++) {
      const currentTag = tagsUuids[c];

      const isExistingTag = !!tags.edges.find((tag: any) => tag && tag.uuid === currentTag);

      isExistingTag === false && newTags.push({ name: { pt: currentTag } });
      isExistingTag && existingTags.push(currentTag);
    }
  }

  return { new: newTags, existing: existingTags };
};

// const removeCustomTags = (values: string | string[], tags?: CategoryList) => {
//   const tagsUuids = optionsToUuid(values);

//   const validTags = [];

//   if (tags && tagsUuids) {
//     for (let c = 0; c < tagsUuids.length; c++) {
//       const currentTag = tagsUuids[c];

//       const isExistingTag = !!tags.edges.find((tag) => tag && tag.uuid === currentTag);

//       isExistingTag && validTags.push(currentTag);
//     }
//   }

//   return validTags;
// };

export const transformInitialValues = (data: FeedPost): any => {
  const {
    title,
    subtitle,
    category,
    tags,
    author,
    cover,
    highlight,
    blocks,
    type,
    published,
  } = data;

  return {
    title: title.pt,
    subtitle: subtitle.pt,
    category: category ? `${category?.name.pt} | ${category?.uuid}` : '',
    tags: tags ? tags.map((tag) => `${tag?.name.pt} | ${tag?.uuid}`) : null,
    author: author ? author.uuid : '',
    cover: cover,
    highlight: highlight ? highlight.image : null,
    highlightValues: highlight,
    blocks: blocks.pt ? blocks.pt.blocks : null,
    type: type,
    publishedApp: published.app,
    publishedSite: published.site,
  };
};

export const capitalizeString = (value: string) => {
  return value.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());
};

const generateTagsForSave = (tags: any, newTags: any, allTags: any) => {
  const existingTags = tags ? (getCustomTags(tags, allTags).existing as string[]) : [];
  const tagsForSave = [
    ...existingTags,
    ...((newTags && newTags.map((item: any) => item.uuid)) || []),
  ];

  return tagsForSave;
};
