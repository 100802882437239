import { Button, DatePicker, Form, Input, Select } from 'antd';
import { ColorGrey01 } from 'config/main';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IconPlus } from 'assets/icons/iconPlus';

import { ChampionshipEntity, ChampionshipGroup, ConfigMatchEntityType } from 'queries/types';

const Container = styled.div`
  background: white;
  border: 1px solid #edf2f9;
  border-radius: 10px;
  padding: 50px;
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-bottom: 25px;

  & > div {
    max-width: 520px;
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  justify-content: center;
`;

const I18N_PREFIX = 'sportsMatch.create';

interface Props {
  form: any;
  championshipGroup?: ChampionshipGroup;
  configMatchEntityType?: ConfigMatchEntityType;
  configMatchEntityMaxNumber?: number | null;
  children?: any;
}

export const ChampionshipMatchForm: React.FC<Props> = ({
  form,
  championshipGroup,
  configMatchEntityType,
  configMatchEntityMaxNumber,
  children,
}) => {
  const { t } = useTranslation();

  const [pairTeamsRequired, setPairTeamsRequired] = useState<boolean>(false);
  const [matchEntityTotal, setMatchEntityTotal] = useState<number | undefined | null>(
    configMatchEntityMaxNumber
  );

  useEffect(() => {
    setMatchEntityTotal(configMatchEntityMaxNumber);
  }, [configMatchEntityMaxNumber]);

  const onTeamChange = () => {
    configMatchEntityType === ConfigMatchEntityType.Pair &&
      setPairTeamsRequired(!!form.getFieldValue('team1') || !!form.getFieldValue('team2'));
  };

  const teams = (championshipGroup?.championshipEntities as ChampionshipEntity[]) || [];
  const options = [
    { label: 'Sem seleção', value: '' },
    ...teams.map((team: ChampionshipEntity) => {
      return { label: team.championshipTeam.team.name, value: team.uuid };
    }),
  ];

  return (
    <>
      <Container>
        <Form.Item
          name="datetime"
          label={t(`${I18N_PREFIX}.fields.datetime.field`)}
          rules={[{ required: true, message: t(`${I18N_PREFIX}.fields.datetime.errorMessage`) }]}
        >
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onChange={() => {}}
            placeholder={t(`${I18N_PREFIX}.fields.datetime.placeholder`)}
          />
        </Form.Item>

        <Form.Item
          name="location"
          label={t(`${I18N_PREFIX}.fields.location.field`)}
          rules={[{ required: true, message: t(`${I18N_PREFIX}.fields.location.errorMessage`) }]}
        >
          <Input placeholder={t(`${I18N_PREFIX}.fields.location.placeholder`)} />
        </Form.Item>
        {children}
      </Container>
      {matchEntityTotal ? (
        <Container>
          {[...Array(matchEntityTotal)].map((_x, index) => (
            <Form.Item
              key={`team${index}`}
              name={`team${index}`}
              label={t(`${I18N_PREFIX}.fields.team.field`)}
              rules={[
                {
                  required: pairTeamsRequired,
                  message: t(`${I18N_PREFIX}.fields.team.errorMessage`),
                },
              ]}
            >
              <Select
                className="w-100"
                placeholder={t(`${I18N_PREFIX}.fields.team.placeholder`)}
                disabled={!championshipGroup}
                options={options}
                onChange={onTeamChange}
              />
            </Form.Item>
          ))}
        </Container>
      ) : null}
      {configMatchEntityMaxNumber === null ? (
        <StyledButton
          type="dashed"
          block
          onClick={() => setMatchEntityTotal((matchEntityTotal ?? 0) + 1)}
          icon={<IconPlus width="12" height="12" fill={ColorGrey01} />}
        >
          Adicionar nova equipa
        </StyledButton>
      ) : null}
    </>
  );
};
