import { UPLOAD_ENDPOINT } from 'config/api';

const fileTypesAllowed =
  'application/pdf, application/x-zip-compressed, application/zip, application/x-rar-compressed, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation, text/plain';

export default class Document {
  constructor({ data, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = data;

    this.documentResult = {
      url: this.data.url || '',
      contentType: this.data.contentType || '',
      text: this.data.text || '',
    };

    this.handleChange.bind(this);

    this.buttonContent = 'Seleccionar Documento';
    this.buttonLoading = 'Loading';
  }

  static get toolbox() {
    return {
      title: 'Documento',
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20"><path fill="#707684" fill-rule="evenodd" d="M18 17a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h9v4a2 2 0 0 0 2 2h4v9Zm1.707-11.293L14.293.293a1 1 0 0 0-.707-.292H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6.415a1 1 0 0 0-.293-.708Z"/></svg>',
    };
  }

  renderDocumentPreview(file) {
    file.innerHTML = `<a href=${this.documentResult.url} target="_blank">${this.documentResult.url}</a>`;
    file.style.display = 'block';
  }

  renderButtonLoading(button) {
    button.classList.add('ant-btn-loading');

    const loading =
      '<span class="ant-btn-loading-icon" style=""><span role="img" aria-label="loading" class="anticon anticon-loading anticon-spin"><svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg></span></span>';

    button.innerHTML = `${loading} <span>${this.buttonLoading}</span>`;
  }

  handleChangeText(input) {
    this.documentResult.text = input.value;
    this.save();
  }

  handleChange(input, documentPreview, button) {
    const formdata = new FormData();

    formdata.append('file', input.files[0]);

    this.renderButtonLoading(button);

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: formdata,
    };
    fetch(UPLOAD_ENDPOINT, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.documentResult = { ...this.documentResult, ...data.data };
        this.save();

        this.renderDocumentPreview(documentPreview);

        button.style.display = 'none';
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renderInputField(name, label, value) {
    /* TEMPLATE FOR FIELD
      <div class="ant-form-item">
        <div class="ant-row ant-form-item-row">
          <div class="ant-col ant-form-item-label">
            <label for="website-form_title" class="ant-form-item-required" title="Título da página">Título da página</label>
          </div>
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <input placeholder="Inserir Título da página" id="website-form_title" class="ant-input" type="text" value="teste teste!">
              </div>
            </div>
          </div>
        </div>
      </div>
    */

    const inputFieldItem = document.createElement('div');
    inputFieldItem.classList.add('ant-form-item');

    const inputFieldItemRow = document.createElement('div');
    inputFieldItemRow.classList.add('ant-row', 'ant-form-item-row');
    inputFieldItem.append(inputFieldItemRow);

    const inputFieldLabelContainer = document.createElement('div');
    inputFieldLabelContainer.classList.add('ant-col', 'ant-form-item-label');
    const inputFieldLabel = document.createElement('label');
    inputFieldLabel.title = label;
    inputFieldLabel.append(label);
    inputFieldLabelContainer.append(inputFieldLabel);
    inputFieldItemRow.append(inputFieldLabelContainer);

    const inputFieldInputControl = document.createElement('div');
    inputFieldInputControl.classList.add('ant-col', 'ant-form-item-control');
    inputFieldItemRow.append(inputFieldInputControl);

    const inputFieldInputControlInput = document.createElement('div');
    inputFieldInputControlInput.classList.add('ant-form-item-control-input');
    inputFieldInputControl.append(inputFieldInputControlInput);

    const inputFieldInputControlInputContent = document.createElement('div');
    inputFieldInputControlInputContent.classList.add('ant-form-item-control-input-content');
    inputFieldInputControlInput.append(inputFieldInputControlInputContent);

    const inputFieldInput = document.createElement('input');
    inputFieldInput.type = 'text';
    inputFieldInput.name = name;
    inputFieldInput.value = value;
    inputFieldInput.required = true;
    inputFieldInput.classList.add('ant-input');
    inputFieldInput.addEventListener('change', () => this.handleChangeText(inputFieldInput));
    inputFieldInput.addEventListener('keydown', () => this.handleChangeText(inputFieldInput));
    inputFieldInput.addEventListener('keyup', () => this.handleChangeText(inputFieldInput));
    inputFieldInputControlInputContent.append(inputFieldInput);

    return inputFieldItem;
  }

  render() {
    const documentArea = document.createElement('div');
    documentArea.classList.add('editor-image-element');

    const button = document.createElement('button');
    button.classList.add('ant-btn', 'ant-btn-primary');
    button.textContent = this.buttonContent;
    button.addEventListener('click', (e) => {
      e.preventDefault();
      inputFile.click();
    });
    if (this.documentResult.url) {
      button.style.display = 'none';
    }

    const documentPreview = document.createElement('div');
    documentPreview.style.display = 'none';
    this.renderDocumentPreview(documentPreview);

    const inputText = this.renderInputField('', 'Título', this.documentResult.text);

    const inputFile = document.createElement('input');
    inputFile.setAttribute('type', 'file');
    inputFile.setAttribute('accept', fileTypesAllowed);
    if (this.documentResult.url) {
      inputFile.setAttribute('value', this.documentResult.url);
    }
    inputFile.addEventListener('change', () => {
      this.handleChange(inputFile, documentPreview, button);
    });

    documentArea.append(inputText);
    documentArea.append(documentPreview);
    documentArea.append(button);
    documentArea.append(inputFile);
    return documentArea;
  }

  save() {
    return this.documentResult;
  }
}
