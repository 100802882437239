import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import { Store } from 'rc-field-form/lib/interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { transformFormValues, transformInitialValues } from './utils';

import { PageHeader } from 'components/pageHeader';
import { RemoveModal } from 'components/removeModal';
import { SubmitButton } from 'components/submitButton';
import { CategoriesCreateEditForm } from 'components/websiteCategories/CategoryCreateEditForm';

import { IconDelete } from 'assets/icons/iconDelete';

import {
  Mutation,
  MutationCreatePageCategoryArgs,
  MutationDeletePageCategoryArgs,
  MutationUpdatePageCategoryArgs,
  Query,
  QueryPageCategoryArgs,
} from 'queries/types';
import {
  CreateWebsiteCategory,
  DeleteWebsiteCategory,
  GetPageCategory,
  UpdateWebsiteCategory,
} from 'queries/websiteCategories';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const I18N_PREFIX = 'pressForm';

export const CategoriesCreateEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { firstLevelUuid, secondLevelUuid, uuid } = useParams<{
    firstLevelUuid: string;
    secondLevelUuid: string;
    uuid: string;
  }>();

  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const section = 'categories';

  const [getCategory, { data: category }] = useLazyQuery<Query, QueryPageCategoryArgs>(
    GetPageCategory,
    {
      variables: {
        uuid,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (uuid) getCategory();
  }, [uuid]);

  const [form] = Form.useForm();

  const [createCategory, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreatePageCategoryArgs
  >(CreateWebsiteCategory);
  const [updateCategory, { loading: isSaving }] = useMutation<
    Mutation,
    MutationUpdatePageCategoryArgs
  >(UpdateWebsiteCategory);
  const [deleteCategory, { loading: isRemoving, data: isRemoved }] = useMutation<
    Mutation,
    MutationDeletePageCategoryArgs
  >(DeleteWebsiteCategory);

  useEffect(() => {
    if (isRemoved) history.push(ROUTES.WebsiteCategories);
  }, [isRemoved]);

  useEffect(() => {
    if (isCreated) history.push(`${ROUTES.WebsiteCategories}`);
  }, [isCreated]);

  const onFinish = async (values: any) => {
    if (uuid) {
      updateCategory({
        variables: {
          uuid,
          input: transformFormValues(values),
        },
      });
    } else {
      //
      createCategory({
        variables: {
          input: transformFormValues(values),
        },
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (uuid && !category?.pageCategory) return null;

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="press-form"
        className="press-create-edit-form"
        initialValues={
          transformInitialValues(
            category?.pageCategory!,
            secondLevelUuid || firstLevelUuid,
            category?.pageCategory.page?.uuid
          ) as Store
        }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <StyledPageHeader
          title={t(`${I18N_PREFIX}.header.${section}.${uuid ? 'edit' : 'create'}`)}
          // breadcrumbs={{ routes, separator: '/' }}
        >
          <ButtonsWrapper>
            {uuid && (
              <Button
                className="mr-2"
                icon={<IconDelete width="14" height="14" className={'anticon'} />}
                htmlType="reset"
                onClick={() => setShowRemoveModal(true)}
              >
                {t(`${I18N_PREFIX}.reset`)}
              </Button>
            )}
            <SubmitButton isLoading={isCreating || isSaving} label={t(`${I18N_PREFIX}.submit`)} />
          </ButtonsWrapper>
        </StyledPageHeader>
        <CategoriesCreateEditForm form={form} props />
      </Form>
      <RemoveModal
        title={t('modals.categoriesRemoveModal.title')}
        message={t('modals.categoriesRemoveModal.message')}
        isLoading={isRemoving}
        showModal={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
        onRemoveConfirm={() =>
          deleteCategory({
            variables: {
              uuid,
            },
          })
        }
      />
    </>
  );
};
