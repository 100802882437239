import { API, OutputData } from '@editorjs/editorjs';
import * as React from 'react';
import EditorJs from 'react-editor-js';
import { useTranslation } from 'react-i18next';

import {
  EDITOR_JS_TOOLS,
  EDITOR_JS_TOOLS_GALLERY,
  EDITOR_JS_TOOLS_VIDEO,
  EDITOR_JS_TOOLS_MEDIA,
  EDITOR_JS_TOOLS_PAGES,
} from './config';

export enum EditorMode {
  DEFAULT = 'default',
  VIDEO = 'video',
  GALLERY = 'gallery',
  MEDIA = 'media',
  PAGES = 'pages',
}

interface Props {
  blocks: any;
  update: (blocks: any) => void;
  mode?: EditorMode;
}

export const Editor: React.VFC<Props> = ({ blocks, update, mode = EditorMode.DEFAULT }: Props) => {
  const instanceRef = React.useRef(null);
  const { t } = useTranslation();

  function handleChange(_api: API, newData: OutputData) {
    update(newData.blocks);
  }

  function handleCompareBlocks(newBlocks: any, oldBlocks: any) {
    if (mode !== EditorMode.DEFAULT && oldBlocks) {
      if (oldBlocks.length > newBlocks.length) {
        showToolbar(true);
      } else if (oldBlocks.length < newBlocks.length) {
        showToolbar(false);
      }
    }
  }

  function showToolbar(show: boolean) {
    const isVideoPage = document.getElementsByClassName('editor-video');
    const isGalleryPage = document.getElementsByClassName('editor-gallery');
    if (isVideoPage.length > 0 || isGalleryPage.length > 0) {
      const editorToolbar = document.getElementsByClassName(
        'ce-toolbar__content'
      ) as HTMLCollectionOf<HTMLElement>;
      if (editorToolbar.length > 0) {
        editorToolbar[0].style.display = show ? 'block' : 'none';
      }
    }
  }

  let modeTools;

  switch (mode) {
    case EditorMode.GALLERY:
      modeTools = EDITOR_JS_TOOLS_GALLERY;
      break;
    case EditorMode.VIDEO:
      modeTools = EDITOR_JS_TOOLS_VIDEO;
      break;
    case EditorMode.MEDIA:
      modeTools = EDITOR_JS_TOOLS_MEDIA;
      break;
    case EditorMode.PAGES:
      modeTools = EDITOR_JS_TOOLS_PAGES;
      break;
    default:
      modeTools = EDITOR_JS_TOOLS;
      break;
  }

  return (
    <EditorJs
      ref={instanceRef}
      // @ts-ignore
      onChange={(api: API, data: OutputData) => handleChange(api, data)}
      // @ts-ignore
      onCompareBlocks={(newBlocks: any, oldBlocks: any) => {
        handleCompareBlocks(newBlocks, oldBlocks);
        return false;
      }}
      tools={modeTools}
      data={{
        blocks: blocks,
      }}
      placeholder={t('editor.placeholder') as string}
      i18n={{
        messages: {
          ui: {
            // Translations of internal UI components of the editor.js core
          },
          toolNames: {
            // Section for translation Tool Names: both block and inline tools
          },
          tools: {
            // Section for passing translations to the external tools classes
            // The first-level keys of this object should be equal of keys ot the 'tools' property of EditorConfig
          },
          blockTunes: {
            // Section allows to translate Block Tunes
          },
        },
      }}
    />
  );
};
