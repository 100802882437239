export default class Link {
  constructor({ data, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = data;

    this.url = this.data.url ? this.data.url : '';
    this.text = this.data.text ? this.data.text : '';
    this.handleChange.bind(this);
  }

  static get toolbox() {
    return {
      title: 'Link',
      icon: '<svg width="13" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M8.567 13.629c.728.464 1.581.65 2.41.558l-.873.873A3.722 3.722 0 1 1 4.84 9.794L6.694 7.94a3.722 3.722 0 0 1 5.256-.008L10.484 9.4a5.209 5.209 0 0 1-.017.016 1.625 1.625 0 0 0-2.29.009l-1.854 1.854a1.626 1.626 0 0 0 2.244 2.35zm2.766-7.358a3.722 3.722 0 0 0-2.41-.558l.873-.873a3.722 3.722 0 1 1 5.264 5.266l-1.854 1.854a3.722 3.722 0 0 1-5.256.008L9.416 10.5a5.2 5.2 0 0 1 .017-.016 1.625 1.625 0 0 0 2.29-.009l1.854-1.854a1.626 1.626 0 0 0-2.244-2.35z" transform="translate(-3.667 -2.7)"></path></svg>',
    };
  }

  handleChange(input) {
    if (input.name === 'url') this.url = input.value;
    if (input.name === 'urlText') this.text = input.value;

    this.save();
  }

  renderInputField(name, label, value) {
    /* TEMPLATE FOR FIELD
      <div class="ant-form-item">
        <div class="ant-row ant-form-item-row">
          <div class="ant-col ant-form-item-label">
            <label for="website-form_title" class="ant-form-item-required" title="Título da página">Título da página</label>
          </div>
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <input placeholder="Inserir Título da página" id="website-form_title" class="ant-input" type="text" value="teste teste!">
              </div>
            </div>
          </div>
        </div>
      </div>
    */

    const inputFieldItem = document.createElement('div');
    inputFieldItem.classList.add('ant-form-item');

    const inputFieldItemRow = document.createElement('div');
    inputFieldItemRow.classList.add('ant-row', 'ant-form-item-row');
    inputFieldItem.append(inputFieldItemRow);

    const inputFieldLabelContainer = document.createElement('div');
    inputFieldLabelContainer.classList.add('ant-col', 'ant-form-item-label');
    const inputFieldLabel = document.createElement('label');
    inputFieldLabel.title = label;
    inputFieldLabel.append(label);
    inputFieldLabelContainer.append(inputFieldLabel);
    inputFieldItemRow.append(inputFieldLabelContainer);

    const inputFieldInputControl = document.createElement('div');
    inputFieldInputControl.classList.add('ant-col', 'ant-form-item-control');
    inputFieldItemRow.append(inputFieldInputControl);

    const inputFieldInputControlInput = document.createElement('div');
    inputFieldInputControlInput.classList.add('ant-form-item-control-input');
    inputFieldInputControl.append(inputFieldInputControlInput);

    const inputFieldInputControlInputContent = document.createElement('div');
    inputFieldInputControlInputContent.classList.add('ant-form-item-control-input-content');
    inputFieldInputControlInput.append(inputFieldInputControlInputContent);

    const inputFieldInput = document.createElement('input');
    inputFieldInput.type = 'text';
    inputFieldInput.name = name;
    inputFieldInput.value = value;
    inputFieldInput.required = true;
    inputFieldInput.classList.add('ant-input');
    inputFieldInput.addEventListener('change', () => this.handleChange(inputFieldInput));
    inputFieldInput.addEventListener('keydown', () => this.handleChange(inputFieldInput));
    inputFieldInput.addEventListener('keyup', () => this.handleChange(inputFieldInput));
    inputFieldInputControlInputContent.append(inputFieldInput);

    return inputFieldItem;
  }

  render() {
    const urlField = this.renderInputField('url', 'URL', this.url);
    const textField = this.renderInputField('urlText', 'Texto do Link', this.text);

    const container = document.createElement('div');
    container.append(urlField);
    container.append(textField);

    return container;
  }

  save() {
    return {
      url: this.url,
      text: this.text,
    };
  }
}
