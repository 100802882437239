import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/config';

import { Categories } from './categories';
import { CategoriesCreateEdit } from './categories/createEdit';
import { Pages } from './pages';
import { PagesCreateEdit } from './pages/createEdit';

export const Website = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES.WebsiteCategories}${ROUTES.Create}`}
      component={CategoriesCreateEdit}
    />
    <Route
      exact
      path={`${ROUTES.WebsiteCategories}/:firstLevelUuid${ROUTES.Create}`}
      component={CategoriesCreateEdit}
    />
    <Route
      exact
      path={`${ROUTES.WebsiteCategories}/:firstLevelUuid/:secondLevelUuid${ROUTES.Create}`}
      component={CategoriesCreateEdit}
    />
    <Route
      exact
      path={`${ROUTES.WebsiteCategories}${ROUTES.Edit}/:uuid`}
      component={CategoriesCreateEdit}
    />
    <Route
      exact
      path={`${ROUTES.WebsiteCategories}/:firstLevelUuid/:secondLevelUuid`}
      component={Categories}
    />
    <Route exact path={`${ROUTES.WebsiteCategories}/:firstLevelUuid`} component={Categories} />
    <Route exact path={ROUTES.WebsiteCategories} component={Categories} />

    <Route exact path={`${ROUTES.WebsitePages}${ROUTES.Create}`} component={PagesCreateEdit} />
    <Route exact path={`${ROUTES.WebsitePages}/:uuid`} component={PagesCreateEdit} />
    <Route exact path={ROUTES.WebsitePages} component={Pages} />
  </Switch>
);
